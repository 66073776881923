import CustomPrompt from 'components/CustomPrompt';
import { CustomPromptType } from 'components/CustomPrompt/types';
import { useSaveChangesModal } from 'components/FormHeader/hooks';
import { AvatarItem } from 'components/lib/Avatar/types';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import List from 'components/List';
import CancelConfigurationModal from 'components/CancelConfigurationModal';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SCROLLABLE_CONTAINER, SELECT_USERS_WRAPPER } from 'utils/elementsIds';
import {
  ADD_OWNERS_PANEL_COUNTER,
  BUTTON_CANCEL_TESTID,
  BUTTON_SUBMIT_TESTID,
  OBJECT_CLASS_PERMISSIONS_MANAGE_OWNERS,
} from 'utils/testIds';
import useAddUsersPanel from './hooks';
import { useAddOwnersStyles } from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/AddUsersPanel/styles';
import { Location } from 'history';
import clsx from 'clsx';
import { AddUsersPanelProps } from './types';
import { useBeforeunload } from 'react-beforeunload';
import { preventDefault } from 'utils/functions/preventDefault';
import { PlusIcon } from 'components/Icon';
import LimitExceededNotification from './components/LimitExceededNotification';
import { useAutoSaveErrorModalContext } from 'contexts/AutoSaveErrorModalContext';
import GroupListElement from '../GroupListElement';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import AutocompleteUserGroupsSelect from 'components/AutocompleteUsersGroupsSelect';
import TooltipString from 'components/TooltipString';

const AddUserGroupsPanel = <T extends object>(props: AddUsersPanelProps) => {
  const {
    itemsLimit,
    usersCount,
    headerTitle,
    autocompleteUrl,
    panelTitle,
    intlLimitMessage,
    searchPlacehlderText,
    customEmptyListText,
  } = props;
  const intl = useIntl();
  const classes = useAddOwnersStyles();
  const {
    checkOptionIsDisabled,
    getDisabledText,
    handleCancel,
    handleSubmit,
    isSubmitting,
    isLimitExceeded,
    isLimitAchieved,
    onSelectChange,
    removeSeletedUser,
    selectedUsers,
    showUnsavedChangesModal,
    onConfirmCancelModal,
    hideUnsavedChangesModal,
    hasSelectedUsers,
  } = useAddUsersPanel<T>(props);
  const { navigateTo, handleLocationChange } = useSaveChangesModal();
  const { isVisibleErrorModal } = useAutoSaveErrorModalContext();

  const onCancelCustomPrompt = (navigateTo?: Location) => {
    if (navigateTo) {
      handleLocationChange(`${navigateTo.pathname}${navigateTo.search}`);
    }
  };

  useBeforeunload(e => hasSelectedUsers && preventDefault(e));

  return (
    <div
      className={classes.contentWrapper}
      data-testid={OBJECT_CLASS_PERMISSIONS_MANAGE_OWNERS}
    >
      <>
        {!!headerTitle && (
          <div className={classes.headerTitle}>{headerTitle}</div>
        )}
        {!!panelTitle && (
          <div className={clsx(classes.headerTitle, classes.panelTitle)}>
            <TooltipString text={panelTitle ?? ''} />
          </div>
        )}
        <div
          className={clsx(classes.bottomGap, classes.text)}
          data-testid={ADD_OWNERS_PANEL_COUNTER}
        >
          <FormattedMessage
            id='misc.groupsSelected'
            defaultMessage='{count, plural, one {# user group selected} other {# user groups selected}}'
            values={{ count: selectedUsers.length }}
          />
        </div>
        {isLimitAchieved ? (
          <LimitExceededNotification
            {...{ itemsLimit, itemsCount: usersCount }}
            customMessage={intlLimitMessage}
          />
        ) : (
          <div id={SELECT_USERS_WRAPPER} className={classes.bottomGap}>
            <AutocompleteUserGroupsSelect
              onChange={onSelectChange}
              className={classes.autocompleteSelect}
              selectMultiple
              {...{
                checkOptionIsDisabled,
                autocompleteUrl,
              }}
              renderOption={(option, searchValue) => {
                return (
                  <OptionGroupAvatar
                    {...{ option, searchValue }}
                    disabledText={getDisabledText(+option.value)}
                    disabled={false}
                  />
                );
              }}
              placeholder={
                searchPlacehlderText ??
                intl.formatMessage({
                  id: 'placeholders.searchForUserGroups',
                  defaultMessage: 'Search for user groups',
                })
              }
            />
          </div>
        )}
      </>
      <div className={classes.scrollableContainer} id={SCROLLABLE_CONTAINER}>
        <List<AvatarItem>
          emptyDataDescription={
            customEmptyListText ??
            intl.formatMessage({
              id: 'owners.noUserGroupsSelected',
              defaultMessage: 'No user groups selected.',
            })
          }
          items={selectedUsers}
          renderItem={item => (
            <GroupListElement
              {...item}
              onDelete={() => removeSeletedUser(item.id)}
            />
          )}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <ButtonSecondaryOutlined
          onClick={handleCancel}
          data-testid={BUTTON_CANCEL_TESTID}
        >
          <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
        </ButtonSecondaryOutlined>
        <ButtonTertiary
          disabled={!hasSelectedUsers || isLimitExceeded}
          onClick={handleSubmit}
          loading={isSubmitting}
          data-testid={BUTTON_SUBMIT_TESTID}
          icon={<PlusIcon size={9} />}
        >
          <FormattedMessage id='misc.add' defaultMessage='Add' />
        </ButtonTertiary>
      </div>
      <CancelConfigurationModal
        visible={showUnsavedChangesModal && !isVisibleErrorModal}
        onCancel={hideUnsavedChangesModal}
        onConfirm={onConfirmCancelModal}
      />
      <CustomPrompt
        when={!navigateTo && hasSelectedUsers && !isVisibleErrorModal}
        onCancel={onCancelCustomPrompt}
        type={CustomPromptType.CANCEL}
      />
    </div>
  );
};

export default AddUserGroupsPanel;
