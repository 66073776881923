import ConfirmationModal from '../ConfirmationModal';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useObjectClassesFieldsStyles from '../../pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassFieldsTable/ObjectClassFieldsTableComponent.styles';
import {
  ActionConfirmationData,
  ActionConfirmationType,
} from '../../contexts/types';
import { useDispatch } from 'react-redux';
import { setSavingPreferences } from '../../store/actions/preferencesActions';
import { CloseIcon, EyeCrossedOut } from '../Icon';
import EyeCrossedOutFull from '../../img/icons/eye-crossed-out-full.svg';
import TrashIllustration from '../../img/illustrations/trash-illustration.svg';
import WarningIllustration from '../../img/illustrations/warning-triangle.svg';
import {
  ActionConfirmationModalProps,
  TableActionConfirmationData,
} from './types';

export const ActionConfirmationModal = ({
  data,
  type,
  refetchTable,
  confirmationData,
  setModalData,
}: ActionConfirmationModalProps) => {
  const intl = useIntl();
  const classes = useObjectClassesFieldsStyles({});
  const dispatch = useDispatch();

  const onCancelAction = useCallback(() => {
    setModalData(undefined);
    dispatch(setSavingPreferences(false));
  }, [dispatch, setModalData]);

  const onConfirmAction = useCallback(
    async (
      confirmActionOnItemMethod?: (data: ActionConfirmationData) => void,
      shouldRefetchOnConfirm?: boolean
    ) => {
      dispatch(setSavingPreferences(true));
      await confirmActionOnItemMethod?.(data);
      onCancelAction();
      if (shouldRefetchOnConfirm) await refetchTable();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, data]
  );

  const getDefaultConfirmTranslation = useCallback(
    (confirmType?: ActionConfirmationType) => {
      switch (confirmType) {
        case ActionConfirmationType.Delete:
          return intl.formatMessage({
            id: 'misc.delete',
            defaultMessage: 'Delete',
          });
        case ActionConfirmationType.Remove:
          return intl.formatMessage({
            id: 'misc.remove',
            defaultMessage: 'Remove',
          });
        case ActionConfirmationType.RemoveChild:
          return intl.formatMessage({
            id: 'misc.removeChild',
            defaultMessage: 'Yes, remove',
          });
        default:
          return intl.formatMessage({
            id: 'misc.ok',
            defaultMessage: 'Ok',
          });
      }
    },
    [intl]
  );

  const getDefaultConfirmIcon = useCallback(
    (confirmType?: ActionConfirmationType) => {
      switch (confirmType) {
        case ActionConfirmationType.Remove:
          return (
            <EyeCrossedOut className={classes.crossedOutEyeWhite} size={12} />
          );
        case ActionConfirmationType.Delete:
          return <CloseIcon size={18} />;
      }
    },
    [classes.crossedOutEyeWhite]
  );

  const getDefaultImage = useCallback(
    (confirmType?: ActionConfirmationType) => {
      switch (confirmType) {
        case ActionConfirmationType.Remove:
          return EyeCrossedOutFull;
        case ActionConfirmationType.RemoveChild:
          return WarningIllustration;
        default:
          return TrashIllustration;
      }
    },
    []
  );

  const {
    title,
    getTitle,
    subtitle,
    getSubtitle,
    confirmActionOnItemMethod,
    image,
    confirmationButtonIcon,
    cancelButtonLabel,
    confirmationButtonLabel,
    shouldRefetchOnConfirm = true,
  } = (confirmationData[type] as TableActionConfirmationData) || {};

  const isDangerAction =
    type === ActionConfirmationType.RemoveChild ? false : true; //only removing child doesn't required this flag

  return (
    <ConfirmationModal
      cancelButtonIcon={<></>}
      {...{
        visible: true,
        onConfirm: () =>
          onConfirmAction(confirmActionOnItemMethod, shouldRefetchOnConfirm),
        onCancel: onCancelAction,
        image: image || getDefaultImage(type),
        confirmationButtonIcon:
          confirmationButtonIcon || getDefaultConfirmIcon(type),
        confirmationButtonLabel:
          confirmationButtonLabel || getDefaultConfirmTranslation(type),
        cancelButtonLabel:
          cancelButtonLabel ||
          intl.formatMessage({
            id: 'misc.cancel',
            defaultMessage: 'Cancel',
          }),
        isDangerAction,
        title: title || getTitle?.(data),
        subtitle: subtitle || getSubtitle?.(data),
      }}
    />
  );
};
