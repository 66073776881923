import React, { useCallback, useRef, useState } from 'react';
import { Dropdown } from 'components/lib/Dropdown';
import { ThreeDotsIcon } from 'components/Icon';
import Button from 'components/lib/Button';
import useActionMenuStyles from './styles';
import { ActionMenuProps } from './types';
import clsx from 'clsx';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { CONTENT_ID } from 'utils/elementsIds';

const ActionMenu: React.FC<ActionMenuProps> = ({
  rowId,
  overlay,
  onClick: onClickAction,
  shouldOverlayBeClosedOnClick,
  disabled,
  testId,
  getPopupContainer,
  withOutsideClick,
  overlayClassName,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const classes = useActionMenuStyles({});
  const [visible, setVisible] = useState(false);

  useOutsideClick(
    buttonRef,
    () => {
      if (withOutsideClick && shouldOverlayBeClosedOnClick) setVisible(false);
    },
    { event: 'mouseup' }
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      if (onClickAction) {
        const { y } =
          document
            .getElementById(`actions-button-${rowId}`)
            ?.getBoundingClientRect() || {};

        onClickAction(y);
      }

      setVisible(true);
    },
    [onClickAction, rowId]
  );

  const onClickOverlay = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (shouldOverlayBeClosedOnClick) setVisible(false);
    },
    [shouldOverlayBeClosedOnClick]
  );

  const handleGetPopupContainer = (triggerNode: HTMLElement) => {
    if (getPopupContainer) {
      return getPopupContainer(triggerNode);
    }

    return document.getElementById(CONTENT_ID) ?? document.body;
  };

  return (
    <Dropdown
      visible={visible}
      disabled={disabled}
      trigger={['click']}
      placement='bottomRight'
      overlay={<div onClick={onClickOverlay}>{overlay}</div>}
      overlayClassName={clsx(classes.dropdownOverlay, overlayClassName)}
      onVisibleChange={setVisible}
      getPopupContainer={handleGetPopupContainer}
    >
      <Button
        id={rowId !== undefined ? `actions-button-${rowId}` : undefined}
        type='text'
        shape='circle'
        {...{ onClick }}
        className={clsx({ [classes.actionButton]: visible })}
        data-testid={testId}
        ref={buttonRef}
      >
        <ThreeDotsIcon size={15} />
      </Button>
    </Dropdown>
  );
};

export default ActionMenu;
