import { AvatarItem } from 'components/lib/Avatar/types';
import { useMemo } from 'react';
import {
  SelectUserGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';

export const useUserAndGroupAvatars = (
  users: SelectUserOption[],
  groups: SelectUserGroupOption[]
) => {
  const avatarItems: AvatarItem[] = useMemo(() => {
    const groupAvatars: AvatarItem[] = groups.map(group => {
      return {
        id: group.id,
        userGroup: group.text,
      };
    });

    const userAvatars: AvatarItem[] = users.map(user => {
      return {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        accountType: user.account_type,
      };
    });

    return groupAvatars.concat(userAvatars);
  }, [users, groups]);

  return { avatarItems };
};
