import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UsersAndGroupsExpandablePickerStylesProps = {
  isError: boolean;
};

export const useUsersAndGroupsExpandablePickerStyles = createUseStyles(
  (theme: ThemeState) => ({
    optionList: {
      width: '100%',
      maxHeight: '256px',
      overflowY: 'scroll',
      boxShadow:
        '0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)',
    },
    errorBorder: {
      boxSizing: 'border-box',
      border: (props: UsersAndGroupsExpandablePickerStylesProps) =>
        props?.isError
          ? `1px solid ${theme['error-color']}`
          : '1px solid transparent',
    },
    validationContainer: {
      borderTop: `1px solid ${theme['border-color-base']}`,
    },
  })
);
