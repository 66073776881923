import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useUserGroupValidationMessages = () => {
  const intl = useIntl();

  const noGroupsToSelect = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.noUserGroupsToSelect',
        defaultMessage: 'No user groups to select',
      }),
    [intl]
  );

  const notEnoughGroupsToSelect = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.notEnoughGroupsToSelect',
        defaultMessage: 'Not enough user groups to select',
      }),
    [intl]
  );

  const noGroupsToSelectError = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.noUserGroupsToSelectError',
        defaultMessage:
          'No user groups to select and save the record. Contact System Administrator.',
      }),
    [intl]
  );

  const notEnoughGroupsToSelectError = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.notEnoughGroupsToSelectError',
        defaultMessage:
          'Not enough user groups to select and save the record. Contact System Administrator.',
      }),
    [intl]
  );

  return {
    noGroupsToSelect,
    notEnoughGroupsToSelect,
    noGroupsToSelectError,
    notEnoughGroupsToSelectError,
  };
};
