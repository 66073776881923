import React, { useCallback, useState } from 'react';
import { Widget } from 'alx-dynamic-form';
import { FormItem } from 'components/lib/Form';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import {
  SelectUserAndGroupOption,
  SelectUserAndGroupOptionMap,
} from 'utils/types/selectInput.types';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { useLocation } from 'react-router-dom';
import MinMaxInfo from 'components/MinMaxInfo';
import { EMPTY_SELECTION } from './CustomUserWidget.consts';
import Tooltip from 'components/lib/Tooltip';
import clsx from 'clsx';
import { useCustomUserWidgetStyles } from './CustomUserWidget.styles';
import {
  useObjectClassUserFieldOptions,
  useValidateFulfillment,
} from './hooks';
import { UsersAndGroupsSelect } from './components/UsersAndGroupsSelect';
import { MinMaxInfoLabelType } from 'components/MinMaxInfo/types';
import { useRevalidate } from 'hooks/useRevalidate';

export const CustomUserWidget: Widget = ({
  value,
  required,
  label,
  fieldAlias,
  description,
  errors,
  onChange,
  reValidateField,
  additionalFieldProps,
  fieldId,
  maxUsers,
  minUsers,
  minGroups,
  maxGroups,
  disabled,
}) => {
  const styles = useCustomWidgetsStyles();
  const { selectStyles } = useCustomUserWidgetStyles();
  const { setValueWithValidation } = useRevalidate(
    value,
    onChange,
    reValidateField
  );

  const [selection, setSelection] = useState<SelectUserAndGroupOptionMap>(
    EMPTY_SELECTION
  );

  const {
    tooltip,
    customErrors,
    validateFulfillment,
  } = useValidateFulfillment();

  const { search } = useLocation();
  const classId =
    additionalFieldProps?.classId ?? new URLSearchParams(search).get('classId'); //if classId is provided by additionalFieldProps (standalone form), use it, otherwise get it from url params

  const validateFulfillmentPossibility = useCallback(
    (options: SelectUserAndGroupOption) =>
      validateFulfillment(
        {
          availableItemsAmount: options.users.length,
          isRequired: required,
          minValues: minUsers,
        },
        {
          availableItemsAmount: options.groups.length,
          isRequired: required,
          minValues: minGroups,
        }
      ),
    [minGroups, minUsers, required, validateFulfillment]
  );

  const { isLoading, options } = useObjectClassUserFieldOptions(
    fieldId,
    classId,
    validateFulfillmentPossibility
  );

  // this little monster wraps usual setSelection (setState) to add additional logic when setSelection is called and passed further
  // to components while keeping the setState asynchronous possibility in the form of prev => newState when passed further.
  const handleSetSelection = useCallback(
    (newSelection: React.SetStateAction<SelectUserAndGroupOptionMap>) => {
      setSelection(prev => {
        const value =
          typeof newSelection === 'function'
            ? newSelection(prev)
            : newSelection;

        const usersAsArray = Array.from(value.users.values());
        const groupsAsArray = Array.from(value.groups.values());

        const userIds = usersAsArray.map(user => user.id);
        const groupIds = groupsAsArray.map(group => group.id);

        const newFormValue =
          userIds.length + groupIds.length > 0
            ? {
                users: userIds,
                user_groups: groupIds,
              }
            : null;

        setValueWithValidation(newFormValue);
        return value;
      });
    },
    [setValueWithValidation]
  );

  const finalErrors = [...errors, ...customErrors];

  const hasLimits = [minUsers, maxUsers, minGroups, maxGroups].some(
    limit => limit !== undefined && limit !== null
  );

  const limits = hasLimits
    ? { minUsers, maxUsers, minGroups, maxGroups }
    : undefined;

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        required={required}
        className={clsx([styles.inputLabel, styles.inputLabelEllipsis])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={finalErrors.length > 0 ? 'error' : undefined}
      >
        <StandardFieldWrapper description={description} errors={finalErrors}>
          <div className={selectStyles} data-testid={`users-${fieldAlias}`}>
            <Tooltip title={tooltip} shouldBeRendered={!!tooltip}>
              <UsersAndGroupsSelect
                isLoadingOptions={isLoading}
                limits={limits}
                setSelection={handleSetSelection}
                options={options}
                selection={selection}
                required={required}
                isFieldDisabled={!!tooltip || disabled}
                errors={finalErrors}
                reValidateField={reValidateField}
              />
            </Tooltip>
          </div>
          <MinMaxInfo
            maximum={maxUsers ?? undefined}
            minimum={minUsers ?? undefined}
            labelType={MinMaxInfoLabelType.Users}
            checkboxField
          />
          <MinMaxInfo
            maximum={maxGroups ?? undefined}
            minimum={minGroups ?? undefined}
            labelType={MinMaxInfoLabelType.Groups}
            checkboxField
          />
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};
