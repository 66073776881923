import { createUseStyles } from 'react-jss';

export const useValidationLimitListStyles = createUseStyles(() => ({
  minMaxInfo: {
    marginTop: 0,
  },
  validationListingContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));
