import { Tooltip } from 'antd';
import { UserGroupTypes } from 'components/UsersGroupsSelector/enums';
import { TabPane, Tabs } from 'components/lib/Tabs';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GroupList } from './components/GroupList';
import { UserList } from './components/UserList';
import { useTabListStyles } from './TabbedUsersAndGroupsList.styles';
import { TabbedUsersAndGroupsListProps } from './types';

export const TabbedUsersAndGroupsList = (
  props: TabbedUsersAndGroupsListProps
) => {
  const styles = useTabListStyles();
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState<string>(UserGroupTypes.USERS);
  const {
    groups,
    maxSelectedGroups,
    users,
    maxSelectedUsers,
    disableMaxedOutTabs,
    clearSearchField,
    testId,
  } = props;
  const usersTabDisabled =
    !!disableMaxedOutTabs &&
    !!maxSelectedUsers &&
    users.length >= maxSelectedUsers;
  const groupsTabDisabled =
    !!disableMaxedOutTabs &&
    !!maxSelectedGroups &&
    groups.length >= maxSelectedGroups;

  const tabs = [
    {
      key: UserGroupTypes.USERS,
      tabName: intl.formatMessage({
        id: 'misc.users',
        defaultMessage: 'Users',
      }),
      TabContent: UserList,
      disabled: usersTabDisabled,
    },
    {
      key: UserGroupTypes.GROUPS,
      tabName: intl.formatMessage({
        id: 'misc.groups',
        defaultMessage: 'Groups',
      }),
      TabContent: GroupList,
      disabled: groupsTabDisabled,
    },
  ];

  const maxOptionsAddedText = intl.formatMessage({
    id: 'misc.maxOptionsAdded',
    defaultMessage: 'Maximum options have been added',
  });

  useEffect(() => {
    if (!groupsTabDisabled && !usersTabDisabled) return;
    if (groupsTabDisabled && usersTabDisabled) return;
    if (groupsTabDisabled) {
      setCurrentTab(UserGroupTypes.USERS);
      return;
    }
    if (usersTabDisabled) setCurrentTab(UserGroupTypes.GROUPS);
  }, [groupsTabDisabled, usersTabDisabled]);

  return (
    <div data-testid={testId}>
      <Tabs
        activeKey={currentTab}
        onTabClick={tab => {
          if (tab === currentTab) {
            return;
          }
          setCurrentTab(tab);
          clearSearchField();
        }}
        destroyInactiveTabPane
        className={styles.tabs}
      >
        {tabs.map(({ key, tabName, TabContent, disabled }) => (
          <TabPane
            key={key}
            tab={
              <Tooltip title={disabled ? maxOptionsAddedText : ''}>
                {tabName}
              </Tooltip>
            }
            disabled={disabled}
          >
            <TabContent {...props} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
