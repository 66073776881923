import { useCallback, useEffect, useState } from 'react';
import take from 'lodash/take';
import drop from 'lodash/drop';
import last from 'lodash/last';
import errorToast from 'utils/functions/errorToast';
import { GetResponse } from 'utils/types';
import { apiCall } from 'utils/api';
import axios from 'axios';
import { useCancelToken } from 'hooks/useCancelToken';

/**
 * When using this hook, it is important to set the id of each list item to the domId returned in each result.
 * This allows the list to be scrolled to the correct place when a new batch is retrieved
 * TDataType - format of data that will be returned as result.
 * TResponseDataType - format of single data entry returned by the API call
 */
export const useInfiniteScroll = <
  TData extends { id: number; value: string | number },
  TResponseData = TData
>({
  endpoint,
  searchText,
  blockInitialLoad,
  responseToDataConversionFunction,
  itemUniqueIdGenerationFunction,
}: {
  endpoint: string;
  searchText?: string;
  blockInitialLoad: boolean;
  /**
   * Function that tells how should the TResponseDataType be converted to compatible TDataType. E.g. if response returns separate
   * name and last name which we want to convert to "name lastname" as TDataType value.
   */
  responseToDataConversionFunction: (data: TResponseData) => TData;
  /**
   * Function that should generate unique id for each item. This is used to scroll to the correct position when new batch is loaded.
   * @param data - single item data
   * @returns unique id for the item
   */
  itemUniqueIdGenerationFunction: (data: TData) => string;
}) => {
  const [results, setResults] = useState<{
    items: TData[];
    next?: string;
    nextPlus1?: string;
    previous?: string;
    previousPlus1?: string;
  }>({ items: [] });
  const { cancelOngoingRequests, createCancelToken } = useCancelToken();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState(0);
  const maxDisplayLength = 2 * limit;
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [hasUnfilteredResults, setHasUnfilteredResults] = useState(false);
  const [loadInProgress, setLoadInProgress] = useState('');
  const genItemId = useCallback(itemUniqueIdGenerationFunction, []);

  const fetchData = useCallback(
    async (url: string) => {
      try {
        setIsLoading(true);
        cancelOngoingRequests();
        const cancelToken = createCancelToken();
        const result = await apiCall.get<GetResponse<TResponseData>>(url, {
          cancelToken,
        });

        const convertedResult = result.data.results.map(q =>
          responseToDataConversionFunction(q)
        );

        const finalResult = convertedResult.map((item: TData) => {
          if (!item.id && item.value) {
            return { ...item, id: item.value };
          }

          return item;
        });

        const finalResponse: GetResponse<TData> = {
          ...result.data,
          results: finalResult,
        };

        setIsLoading(false);
        return finalResponse;
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        errorToast();
      }
    },
    [cancelOngoingRequests, createCancelToken, responseToDataConversionFunction]
  );

  const modifySearchParams = useCallback(
    (urlParams: URLSearchParams, search?: string) => {
      if (search) {
        urlParams.set('text__icontains', search);
      }

      return urlParams;
    },
    []
  );

  const getFirstBatch = async () => {
    const newUrl = new URL(endpoint, 'http://doesntmatter.com');
    newUrl.search = modifySearchParams(
      newUrl.searchParams,
      searchText
    ).toString();
    const res = await fetchData(`${newUrl.pathname}${newUrl.search}`);

    if (!res) {
      return;
    }

    setResults({
      items: res.results ?? [],
      next: res.next ?? undefined,
      nextPlus1: res.next ?? undefined,
      previous: res.previous ?? undefined,
      previousPlus1: res.previous ?? undefined,
    });
    setLimit(res.results?.length ?? 50);
    setInitialLoadComplete(true);
    setHasUnfilteredResults(!!res.total_count);
  };

  const getNextBatch = async () => {
    if (results.next && results.next !== loadInProgress) {
      setLoadInProgress(results.next);
      const newUrl = new URL(results.next);
      newUrl.search = modifySearchParams(
        newUrl.searchParams,
        searchText
      ).toString();
      const res = await fetchData(`${newUrl.pathname}${newUrl.search}`);

      if (!res) {
        return;
      }

      const newItems = [...results.items, ...(res.results ?? [])];
      const finalItems =
        newItems.length > maxDisplayLength ? drop(newItems, limit) : newItems;

      setResults({
        items: finalItems,
        next: res.next ?? undefined,
        nextPlus1: res.next ?? undefined,
        previous: results.previousPlus1,
        previousPlus1: res.previous ?? undefined,
      });

      setLoadInProgress('');
    }
  };

  const getPrevBatch = async () => {
    if (results.previous && results.previous !== loadInProgress) {
      setLoadInProgress(results.previous);
      const newUrl = new URL(results.previous);
      newUrl.search = modifySearchParams(
        newUrl.searchParams,
        searchText
      ).toString();
      const res = await fetchData(`${newUrl.pathname}${newUrl.search}`);

      if (!res) {
        return;
      }

      const newItems = [...(res.results ?? []), ...results.items];
      const finalItems =
        newItems.length > maxDisplayLength
          ? take(newItems, maxDisplayLength)
          : newItems;

      setResults({
        items: finalItems,
        next: results.nextPlus1,
        nextPlus1: res.next ?? undefined,
        previous: res.previous ?? undefined,
        previousPlus1: results.previous,
      });
      setLoadInProgress('');

      const lastResultItem = last(res.results);

      if (!lastResultItem) {
        return;
      }

      const lastId = genItemId(lastResultItem);
      const element = document.getElementById(lastId);
      element?.scrollIntoView({
        block: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (blockInitialLoad) {
      return;
    }
    getFirstBatch();

    return () => {
      cancelOngoingRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, blockInitialLoad]);

  const onScroll = (e: React.UIEvent) => {
    const target = e.target as HTMLElement;
    const { scrollTop, scrollHeight, offsetHeight } = target;
    const hasScrollReachedBottom = offsetHeight + scrollTop > scrollHeight - 40;
    if (hasScrollReachedBottom) {
      getNextBatch();
    } else if (target.scrollTop <= 0) {
      getPrevBatch();
    }
  };
  return {
    onScroll,
    loading: loadInProgress || isLoading,
    initialLoadComplete,
    hasUnfilteredResults,
    results: results.items.map(item => {
      return {
        domId: genItemId(item),
        item,
      };
    }),
  };
};
