import React from 'react';
import UserAvatar from 'components/UserAvatar';
import useGroupListElementStyles from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement/styles';
import {
  GROUP_LIST_ITEM_DELETE_BTN_TEST_ID,
  GROUP_LIST_ITEM_NAME_TEST_ID,
  GROUP_LIST_ITEM_TEST_ID,
} from 'utils/testIds';
import { CloseIcon } from 'components/Icon';
import { GroupListElementProps } from './types';
import Tooltip from 'components/lib/Tooltip';
import clsx from 'clsx';

const GroupListElement = ({
  userGroup,
  id,
  actionElement,
  onDelete,
  disablePopover = false,
  groupNoPermissionText,
  truncateTooLongNames = false,
}: GroupListElementProps) => {
  const classes = useGroupListElementStyles();

  return (
    <div className={classes.wrapper} data-testid={GROUP_LIST_ITEM_TEST_ID}>
      <div className={classes.box}>
        <UserAvatar
          {...{ userGroup, disablePopover, groupNoPermissionText }}
          id={id}
          size='large'
        />
        <div
          className={classes.userData}
          data-testid={GROUP_LIST_ITEM_NAME_TEST_ID}
        >
          {truncateTooLongNames ? (
            <Tooltip title={userGroup}>
              <p className={clsx(classes.name, classes.truncatedName)}>
                {userGroup}
              </p>
            </Tooltip>
          ) : (
            <p className={classes.name}>{userGroup}</p>
          )}
        </div>
      </div>
      {!!actionElement && actionElement}
      {!!onDelete && (
        <span data-testid={GROUP_LIST_ITEM_DELETE_BTN_TEST_ID}>
          <CloseIcon onClick={onDelete} size={16} />
        </span>
      )}
    </div>
  );
};

export default GroupListElement;
