import React, { useCallback, useEffect, useState } from 'react';
import {
  ExpandedUserListProps,
  SelectGroupMemberOption,
} from './ExpandedUserList.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useExpandedUserListStyles } from './ExpandedUserList.styles';
import { LabeledSwitch } from 'components/lib/Switch';
import { USER_GROUP_USERS } from 'utils/endpoints';
import { generatePath } from 'react-router-dom';
import {
  UserGroupMembership,
  GroupMember,
} from 'utils/types/api/userGroups.types';
import { Loader } from 'components/lib/Loader';
import { AccountTypeOptions } from 'pages/Users/enums';
import { Input } from 'components/lib/Input';
import { SearchBoldIcon } from 'components/Icon';
import { debounce } from 'lodash';
import { useInfiniteScroll } from 'components/UsersGroupsSelector/hooks';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { MemoizedUsersGroupsListItemSelectAndDeselect } from 'components/UsersGroupsSelector/components/UsersGroupsListItemSelectAndDeselect';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersGroupsSelector/components/UsersAndGroupsExpandablePicker/contexts/UsersAndGroupsExpandablePickerContext';
import Tooltip from 'components/lib/Tooltip';

/**
 * Represents the right side panel content in UserAndGroupsExpandablePicker when a group got clicked
 */
export const ExpandedUserList = ({ userGroup }: ExpandedUserListProps) => {
  const intl = useIntl();
  const styles = useExpandedUserListStyles();

  const {
    selectedItems,
    toggleItemSelectionState,
  } = useUsersAndGroupsExpandablePickerContext();

  const [searchText, setSearchText] = useState<string>('');

  const apiPath = generatePath(`${USER_GROUP_USERS}?membership=member`, {
    id: userGroup.id,
  });

  const { results, initialLoadComplete, onScroll } = useInfiniteScroll<
    SelectGroupMemberOption,
    GroupMember
  >({
    endpoint: apiPath,
    blockInitialLoad: false,
    searchText: searchText,
    responseToDataConversionFunction: groupMember => ({
      ...groupMember,
      type: UserOrGroupSelectOptionType.User,
      account_type: AccountTypeOptions.Standard,
      company: groupMember.company_name,
      is_deleted: false,
      text: `${groupMember.first_name} ${groupMember.last_name} (${groupMember.company_name})`,
      value: groupMember.id,
    }),
    itemUniqueIdGenerationFunction: item => `${item.type}-${item.id}`,
  });

  const debouncedSearch = useCallback(
    debounce((searchText: string) => {
      setSearchText(searchText);
    }, 200),
    []
  );

  const onSearchTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(event.target.value);
    },
    [debouncedSearch]
  );

  const onSyncMembersChange = useCallback(() => {
    toggleItemSelectionState(userGroup);
  }, [toggleItemSelectionState, userGroup]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const isSyncGroupMembers = !!selectedItems.groups.get(userGroup.id);

  return (
    <div className={styles.contentContainer}>
      <div className={styles.controlsContainer}>
        <Input
          prefix={<SearchBoldIcon size={12} className={styles.searchIcon} />}
          onChange={onSearchTextChange}
          className={styles.searchInput}
          placeholder={intl.formatMessage({
            id: 'placeholders.searchForUsers',
            defaultMessage: 'Search for users',
          })}
        />
        <Tooltip
          overlayClassName={styles.tooltipStyle}
          overlay={
            <FormattedMessage
              id='userGroups.syncGroupMembersTooltip'
              defaultMessage='Automatically adds and removes users based on the membership of the group.'
            />
          }
        >
          <LabeledSwitch
            label={intl.formatMessage({
              id: 'userGroups.syncGroupMembers',
              defaultMessage: 'Sync group members',
            })}
            checked={isSyncGroupMembers}
            onChange={onSyncMembersChange}
          />
        </Tooltip>
      </div>
      <div className={styles.userListContainer} onScroll={onScroll}>
        {!initialLoadComplete ? (
          <div className={styles.loadingContainer}>
            <Loader size='small' />
          </div>
        ) : (
          results.map(({ domId, item: user }) => {
            return (
              <MemoizedUsersGroupsListItemSelectAndDeselect
                id={domId}
                key={user.id}
                item={user}
                handleClick={toggleItemSelectionState}
                selected={
                  !!selectedItems.users.get(user.id) || isSyncGroupMembers
                }
                isDisabled={isSyncGroupMembers}
                searchText={searchText}
                limitReached={false}
                additionalText={
                  user.membership === UserGroupMembership.Owner
                    ? 'Group owner'
                    : undefined
                }
              />
            );
          })
        )}
      </div>
    </div>
  );
};
