import { applyFilesMetaToFormFields } from 'components/FormPreview2/utils';
import { CustomFileItem } from 'components/lib/FileUpload/types';
import { toast } from 'components/lib/toast';
import useData from 'hooks/useData';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { OBJECT_RECORD_DETAILS } from 'utils/endpoints';
import { SingleObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { UseInPlaceEditUploadFileParams } from './types';

export const useInPlaceEditUploadFile = <R>({
  recordId,
  identifier,
  endpointUrl,
  propertyName,
  setTempValue,
  isEditMode,
  skipUploading = false,
  onChange,
  onSaveSuccess,
}: UseInPlaceEditUploadFileParams<R>) => {
  const intl = useIntl();
  const [, { loading, error, fetchData }] = useData<
    R & SingleObjectRecordDetails
  >(
    skipUploading || !recordId
      ? ''
      : generatePath(OBJECT_RECORD_DETAILS, {
          id: recordId,
        }),
    {
      fetchOnLoad: false,
    }
  );

  const getParsedFileData = useCallback(
    (data?: SingleObjectRecordDetails) => {
      const parsedFormFieldsWithFiles = applyFilesMetaToFormFields({
        defaultKeysOfFieldsWithFiles: { [propertyName]: propertyName },
        filesMeta: data?._meta?.labels?.files,
        formData: { [propertyName]: data?.[propertyName] },
      });
      return (parsedFormFieldsWithFiles[propertyName] ||
        []) as CustomFileItem[];
    },
    [propertyName]
  );

  const updateRecord = async () => {
    const { data } = await fetchData();
    const parsedFileData = getParsedFileData(data);
    if (onChange) onChange(parsedFileData);
    if (onSaveSuccess && data) onSaveSuccess(data, parsedFileData);
    setTempValue(parsedFileData);
  };

  const onUploadCompleted = async (token: string, fileName: string) => {
    try {
      await apiCall.post(endpointUrl, [token]);
      await updateRecord();

      toast({
        title: intl.formatMessage({
          id: 'misc.success',
          defaultMessage: 'Success!',
        }),
        subtitle: intl.formatMessage(
          {
            id: 'misc.fileForRecordHasBeenUploaded',
            defaultMessage:
              'File {fileName} has been uploaded to {identifier}.',
          },
          { fileName, identifier }
        ),
      });
    } catch (err) {
      throw new Error(
        intl.formatMessage(
          {
            id: 'misc.fileForRecordHasNotBeenUploaded',
            defaultMessage:
              'File {fileName} failed to uploaded to {identifier}.',
          },
          { fileName, identifier }
        )
      );
    }
  };

  useEffect(() => {
    if (isEditMode) {
      fetchData().then(({ data }) => setTempValue(getParsedFileData(data)));
    }
  }, [fetchData, getParsedFileData, isEditMode, setTempValue]);

  return {
    updateRecord,
    onUploadCompleted,
    error,
    loading,
  };
};
