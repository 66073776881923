import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FlexContentWrapper from 'components/wrappers/FlexContentWrapper';
import useFilterEditor from './hooks';
import { ButtonPrimary, ButtonOutlined } from 'components/lib/Button';
import ColumnSelect from 'components/Filters/ColumnSelect';
import SingleFilter from './SingleFilter';
import { CheckOutlined } from '@ant-design/icons';
import { PlusIcon, TrashIcon } from 'components/Icon';
import ConfirmationModal from 'components/ConfirmationModal';
import TrashIllustration from 'img/illustrations/trash-illustration.svg';
import useFilterEditorStyles from './styles';
import { filtersWrapper, SCROLLABLE_CONTAINER } from 'utils/elementsIds';
import { useWindowContext } from 'components/lib/FlexLayout/WindowContext';
import { ToolButtonWithTooltip } from 'components/ToolButton';
import useAppliedFilters from 'hooks/useAppliedFilters';
import NoDataFoundMessage from 'components/NoDataFoundMessage';
import {
  ADD_CONDITION_BUTTON_TESTID,
  APPLY_FILTERS_TESTID,
  REMOVE_ALL_FILTERS_TESTID,
} from 'utils/testIds';
import LoaderOverlay from 'components/LoaderOverlay';
import TablesType from 'utils/Enums/TablesType';
import { RECORDS_FILTERS_LIMIT } from './consts';

const FilterEditor = () => {
  const {
    filters,
    onApplyFilters,
    onCreateFilter,
    columns = {},
    areFiltersValid,
    onClearFilter,
    usedColumns,
    showClearFilterModal,
    setShowClearFilterModal,
    hasNoFilters,
    allFiltersSet,
    onRemoveFilter,
    onClickClearFilters,
    currentTable: currentTableName,
    tmpPredicateSet,
    onPredicateSetChange,
  } = useFilterEditor();

  const intl = useIntl();
  const { windowRef } = useWindowContext();
  const appliedFilters = useAppliedFilters();
  const classes = useFilterEditorStyles({});

  if (!currentTableName || Object.keys(columns).length === 0)
    return <LoaderOverlay />;

  if (hasNoFilters) {
    return (
      <FlexContentWrapper id={filtersWrapper}>
        <NoDataFoundMessage
          title={intl.formatMessage({
            id: 'misc.cannotAddFilters',
            defaultMessage: 'You cannot add any filters',
          })}
          subtitle={intl.formatMessage({
            id: 'misc.noDataToFilter',
            defaultMessage:
              'There is no data on this page that can be filtered',
          })}
        />
      </FlexContentWrapper>
    );
  }

  const isRecordFiltersLimitReached =
    currentTableName === TablesType.ObjectRecords &&
    filters.length >= RECORDS_FILTERS_LIMIT;

  return (
    <div id={filtersWrapper} className={classes.filterEditorMainWrapper}>
      <div
        id={SCROLLABLE_CONTAINER}
        className={classes.filterEditorConditionsWrapper}
      >
        {!allFiltersSet && (
          <div className={classes.buttonWrapper}>
            <ToolButtonWithTooltip
              disabled={
                !areFiltersValid() ||
                filters.length === 0 ||
                isRecordFiltersLimitReached
              }
              tooltipGetPopupContainer={() =>
                windowRef?.document?.body || document.body
              }
              tooltipTitle={intl.formatMessage({
                id: 'misc.addCondition',
                defaultMessage: 'Add condition',
              })}
              disabledTooltipTitle={
                isRecordFiltersLimitReached
                  ? intl.formatMessage(
                      {
                        id: 'misc.maxNFiltersHaveBeenAdded',
                        defaultMessage:
                          'The maximum {limit} filters have been added',
                      },
                      { limit: RECORDS_FILTERS_LIMIT }
                    )
                  : undefined
              }
              customButton={
                <ButtonOutlined
                  icon={<PlusIcon size={14} />}
                  disabled={
                    !areFiltersValid() ||
                    filters.length === 0 ||
                    isRecordFiltersLimitReached
                  }
                  onClick={onCreateFilter}
                  data-testid={ADD_CONDITION_BUTTON_TESTID}
                  className={classes.addConditionButton}
                />
              }
            />
          </div>
        )}
        {filters.length === 0 && (
          <div className={classes.singleFilter}>
            <label>
              <FormattedMessage id='misc.field' defaultMessage='Field' />
            </label>
            <ColumnSelect
              onChange={onCreateFilter}
              {...{ columns, onPredicateSetChange }}
              predicateSet={tmpPredicateSet}
            />
          </div>
        )}
        {filters.length > 0 &&
          filters.map(filter => (
            <SingleFilter
              key={filter.column}
              {...{
                columns,
                filter,
                usedColumns,
                onRemoveFilter,
              }}
            />
          ))}
        <ConfirmationModal
          onConfirm={onClearFilter}
          onCancel={() => setShowClearFilterModal(false)}
          visible={showClearFilterModal}
          title={intl.formatMessage({
            id: 'misc.doYouWantToRemoveAllConditions',
            defaultMessage: 'Do you want to remove all conditions?',
          })}
          subtitle={
            appliedFilters
              ? intl.formatMessage({
                  id: 'misc.filterWillBeReset',
                  defaultMessage:
                    'The filter will become empty. This action cannot be undone.',
                })
              : intl.formatMessage({
                  id: 'misc.filterWillBecomeEmpty',
                  defaultMessage: 'The filter will become empty.',
                })
          }
          cancelButtonLabel={intl.formatMessage({
            id: 'misc.cancel',
            defaultMessage: 'Cancel',
          })}
          confirmationButtonLabel={intl.formatMessage({
            id: 'misc.remove',
            defaultMessage: 'Remove',
          })}
          image={TrashIllustration}
          isDangerAction
          confirmationButtonIcon={<TrashIcon size={12} />}
        />
      </div>

      <div className={classes.bottomButtonsContainer}>
        {filters.length > 0 && (
          <ButtonOutlined
            icon={<TrashIcon size={14} />}
            onClick={onClickClearFilters}
            className={classes.leftButton}
            data-testid={REMOVE_ALL_FILTERS_TESTID}
          >
            <FormattedMessage id='misc.removeAll' defaultMessage='Remove all' />
          </ButtonOutlined>
        )}
        <ButtonPrimary
          className={classes.rightButton}
          onClick={onApplyFilters}
          disabled={!areFiltersValid(true)}
          icon={<CheckOutlined size={15} />}
          data-testid={APPLY_FILTERS_TESTID}
        >
          <FormattedMessage
            id='misc.applyFilter'
            defaultMessage='Apply filter'
          />
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default FilterEditor;
