import React from 'react';
import { UsersGroupsListItemSelectAndDeselect } from '../../../UsersGroupsListItemSelectAndDeselect';
import { isNil } from 'lodash';
import { ExpandablePickerUserGroupItem } from './components/ExpandablePickerUserGroupItem';
import { ExpandablePickerItemListProps } from './ExpandablePickerItemList.types';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { useUsersAndGroupsExpandablePickerContext } from '../../contexts/UsersAndGroupsExpandablePickerContext';

/**
 * Shows a combined list of users and user groups.
 * Users are directly selectable as a toggle.
 * Groups are expandable with a click to its user list and additional UX options.
 */
export const ExpandablePickerItemList = ({
  items,
}: ExpandablePickerItemListProps) => {
  const {
    selectedItems,
    toggleItemSelectionState,
    limits,
    searchText,
  } = useUsersAndGroupsExpandablePickerContext();

  const maxUsersLimitReached =
    !isNil(limits) &&
    !isNil(limits.maxUsers) &&
    limits.maxUsers <= selectedItems.users.size;

  return (
    <>
      {items.map(item => {
        const { id } = item;

        if (item.type === UserOrGroupSelectOptionType.User) {
          return (
            <UsersGroupsListItemSelectAndDeselect
              key={`user-${id}`}
              item={item}
              handleClick={toggleItemSelectionState}
              limitReached={maxUsersLimitReached}
              selected={selectedItems.users.get(id) !== undefined}
              searchText={searchText}
            />
          );
        }

        return (
          <ExpandablePickerUserGroupItem
            key={`group-${id}`}
            item={item}
            limitReached={false}
          />
        );
      })}
    </>
  );
};
