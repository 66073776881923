import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useValidationListingStyles = createUseStyles(
  (theme: ThemeState) => ({
    validationContainer: {
      borderTop: `1px solid ${theme['border-color-base']}`,
    },
  })
);
