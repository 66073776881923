import React from 'react';
import { ValidationErrorListProps } from './ValidationErrorList.types';
import MessageBox from 'components/InPlaceEditControls/components/MessageBox';

/**
 * Lists errors below users and groups picker list (if any)
 */
export const ValidationErrorList = ({ errors }: ValidationErrorListProps) => {
  if (errors.length === 0) {
    return <></>;
  }

  const errorMessage = errors[0];

  return <MessageBox key={`info-errors`} message={errorMessage} isError />;
};
