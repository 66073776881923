import React, { useEffect, useRef, useState } from 'react';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import useShouldRenderOnTop from 'hooks/useShouldRenderOnTop';
import { getContainerRef } from 'components/InPlaceEditControls/InPlaceEditMultiSelect/utils';
import { useUsersAndGroupsSelectStyles } from './UsersAndGroupsSelect.styles';
import { UsersGroupsSelector } from 'components/UsersGroupsSelector';
import { UsersAndGroupsSelectProps } from './UsersAndGroupsSelect.types';
import { useSelectUsersAndGroups, useUserAndGroupAvatars } from './hooks';
import { Select as AntSelect } from 'antd';
import clsx from 'clsx';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { UsersAndGroupsExpandablePicker } from 'components/UsersGroupsSelector/components/UsersAndGroupsExpandablePicker';

export const UsersAndGroupsSelect = (props: UsersAndGroupsSelectProps) => {
  const {
    isLoadingOptions,
    options,
    selection,
    setSelection,
    isFieldDisabled,
    withUnderline = true,
    errors,
    limits,
    reValidateField,
  } = props;

  const userGroupSelectorRef = useRef<AntSelect | null>(null);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const styles = useUsersAndGroupsSelectStyles({
    isFieldDisabled,
    withUnderline,
    hasErrors: errors && errors.length > 0,
  });

  const { isSelectOpen, onSelectOpenChange } = useSelectUsersAndGroups(props);
  const [isExpandedPanelOpen, setIsExpandedPanelOpen] = useState<boolean>(
    false
  );

  const { elementRef, customHeight } = useShouldRenderOnTop<HTMLDivElement>(
    getContainerRef
  );

  const { avatarItems } = useUserAndGroupAvatars(
    Array.from(selection.users.values()),
    Array.from(selection.groups.values())
  );

  useEffect(() => {
    if (!isSelectOpen || !userGroupSelectorRef.current) {
      return;
    }

    userGroupSelectorRef.current.focus();
  }, [isSelectOpen]);

  // We need to provide scrollable parent function to UsersGroupsSelector as it will fail to catch scrollable container
  // when going from user avatars to isOpen state.
  const { getScrollableParent } = useScrollableParent(mainContainerRef);

  const selectionCount = selection.groups.size + selection.users.size;

  return (
    <div ref={mainContainerRef}>
      {isSelectOpen || selectionCount === 0 ? (
        <div
          ref={elementRef}
          className={clsx(
            styles.editUserWrapper,
            styles.dropdownAvatarsCommonHeight
          )}
          style={{ height: customHeight }}
        >
          <UsersGroupsSelector
            ref={userGroupSelectorRef}
            getPopupContainer={getScrollableParent}
            errors={errors}
            className={styles.optionContent}
            dropdownClassName={styles.mainDropdown}
            value={{
              users: Array.from(selection.users.values()),
              groups: Array.from(selection.groups.values()),
            }}
            externalDropdownControl={{
              isDropdownOpen: isSelectOpen,
              onDropdownVisibleChange: open => {
                if (isExpandedPanelOpen) {
                  return;
                }

                onSelectOpenChange(open);

                if (reValidateField) {
                  reValidateField();
                }
              },
            }}
            popoverContent={({ searchText }) => (
              <UsersAndGroupsExpandablePicker
                availableItems={options}
                selectedItems={selection}
                setSelectedItems={setSelection}
                limits={limits}
                isLoadingAvailableItems={isLoadingOptions}
                searchFilter={searchText}
                onExpandedPanelStateChange={setIsExpandedPanelOpen}
                errors={errors}
              />
            )}
          />
        </div>
      ) : (
        <div
          className={clsx(styles.avatars, styles.dropdownAvatarsCommonHeight)}
          onClick={() => onSelectOpenChange(true)}
        >
          <CustomAvatarGroup items={avatarItems} disablePopover />
        </div>
      )}
    </div>
  );
};
