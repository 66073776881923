import { SINGLE_SELECT_MAX_OPTIONS_COUNT } from "components/formBuilder/formBuilder/FBGeneralInput/ControlPreferences/consts";
import { useFormikContext } from "formik";
import { ObjectClassFieldTypes } from "utils/types/api/objectClassesFields.types";
import { ParsedTypes } from "pages/ObjectClasses/components/ClassFieldFormWrapper/types";
import { ClassFieldForm } from "../../../../../types";
import { useValidators } from "../../../hooks";

export const useEnumSetValidators = (parsedTypes: ParsedTypes) => {
    const {
        values: { type },
    } = useFormikContext<ClassFieldForm>();
    const { options: { validators = [] } = {} } = (parsedTypes || {})[type] || {};

    const {
        max_option_length: optionMaxLength,
        min_option_length: optionMinLength,
        max_options: maxOptions,
    } = useValidators(validators);

    const maxOptionsCount =
        type === ObjectClassFieldTypes.Enum
            ? SINGLE_SELECT_MAX_OPTIONS_COUNT
            : maxOptions;

    return {
        optionMaxLength,
        optionMinLength,
        maxOptionsCount,
    };
};