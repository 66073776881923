import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { OBJECT_CLASS_FIELD_DETAILS } from 'utils/endpoints';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';

export const fetchUserFieldUsersAndGroups = async (
  objectClassId: string,
  objectClassFieldId: number
) => {
  const result = await apiCall.get<ObjectRecordDetails>(
    generatePath(OBJECT_CLASS_FIELD_DETAILS, {
      id: objectClassId,
      fieldId: objectClassFieldId,
    })
  );

  return {
    users: result.data._meta.users,
    groups: result.data._meta.user_groups,
  };
};
