import { FormikErrors, FormikHelpers } from "formik";
import { usePostWithToasts } from "hooks/usePostWithToasts";
import { useToggle } from "hooks/useToggle";
import { partition, omit } from "lodash";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useParams, useHistory, generatePath } from "react-router-dom";
import { setSidebarData } from "store/actions/flexLayoutActions";
import { addField, updateField, setObjectClassesFieldsSelectedRow, removeField } from "store/actions/objectClassesFieldsActions";
import FlexLayoutWindows from "utils/Enums/FlexLayoutWindows";
import { FormMode } from "utils/Enums/FormMode";
import TablesType from "utils/Enums/TablesType";
import { isNotFound, isBadRequest } from "utils/apiUtils";
import { OBJECT_CLASS_FIELD_DETAILS, OBJECT_CLASS_DETAILS_FIELDS } from "utils/endpoints";
import objectRemoveKeysWithValue from "utils/functions/objectRemoveKeysWithValue";
import { ObjectClassFieldTypes, ObjectClassFieldUpdated } from "utils/types/api/objectClassesFields.types";
import { aliasNameErrorRegexp } from "../consts";
import { getAvailableUsers, getAvailableGroups } from "../utils";
import { useClassFieldData } from "./useClassFieldData";
import { ClassFieldFormFields } from "pages/ObjectClasses/enums";
import { ClassFieldForm } from "../components/ClassFieldForm/types";
import { ClassFieldDetailLocationState } from "../types";

export const useClassFieldForm = (
    mode: FormMode,
    panelId: FlexLayoutWindows
) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { state: { id } = {} } = useLocation<ClassFieldDetailLocationState>();
    const { id: classId } = useParams<{ id: string }>();
    const { getFieldData, data, ...rest } = useClassFieldData();
    const [
        isOpenNoExistsFieldModal,
        { toggleOn: openNoExistsFieldModal, toggleOff: closeNoExistsFieldModal },
    ] = useToggle(false);
    const history = useHistory();

    useEffect(() => {
        if (id === undefined || classId === undefined) return;

        getFieldData(classId, id);
    }, [getFieldData, classId, id]);

    const initialData = useMemo(() => {
        if (mode === FormMode.Create) return undefined;

        return data;
    }, [data, mode]);

    const {
        alias = '',
        label = '',
        type = ObjectClassFieldTypes.String,
        _meta: { users = [], user_groups = [] } = {},
        ...defaultValues
    } = initialData || {};

    const [activeUsers, deletedUsers] = partition(
        users,
        ({ is_deleted }) => !is_deleted
    );

    const transformedGroups = user_groups.map(ug => {
        return { ...ug, text: ug.name ?? '' };
    });

    const [initialValues, setInitialValues] = useState<ClassFieldForm>({
        [ClassFieldFormFields.Alias]: alias,
        [ClassFieldFormFields.Label]: label,
        [ClassFieldFormFields.Type]: type,
        [ClassFieldFormFields.Unique]: undefined,
        [ClassFieldFormFields.Identifier]: undefined,
        [ClassFieldFormFields.Users]: activeUsers,
        [ClassFieldFormFields.Groups]: transformedGroups,
        ...objectRemoveKeysWithValue(defaultValues, undefined),
    });

    useEffect(() => {
        setInitialValues({
            [ClassFieldFormFields.Alias]: alias,
            [ClassFieldFormFields.Label]: label,
            [ClassFieldFormFields.Type]: type,
            [ClassFieldFormFields.Unique]: undefined,
            [ClassFieldFormFields.Identifier]: undefined,
            [ClassFieldFormFields.Users]: activeUsers,
            [ClassFieldFormFields.Groups]: transformedGroups,
            ...objectRemoveKeysWithValue(defaultValues, undefined),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alias]);

    const { sendData, isLimitExceeded } = usePostWithToasts<
        ClassFieldForm,
        ObjectClassFieldUpdated
    >(mode, TablesType.ObjectClassesFields);

    const parseFormDataToApi = (formData: ClassFieldForm) => {
        const { users = [], user_groups = [], ...values } = formData;
        const isUserType = values.type === ObjectClassFieldTypes.User;

        return {
            ...values,
            ...(isUserType && {
                options: {
                    user_groups: user_groups.map(({ id }) => id),
                    users: users.map(({ id }) => id),
                },
            }),
            default_value: getDefaultValue(formData),
            ...(mode === FormMode.Create && { order: 0 }),
        };
    };

    const getDefaultValue = (formData: ClassFieldForm) => {
        const { default_value: defaultValue, type } = formData;

        if (type === ObjectClassFieldTypes.Bool) return !!defaultValue;

        return defaultValue === '' ? null : defaultValue;
    };

    const parseAndSetErrors = useCallback(
        (setErrors: (errors: FormikErrors<ClassFieldForm>) => void) => (
            errors: FormikErrors<ClassFieldForm>
        ) => {
            if (errors.alias && aliasNameErrorRegexp.test(errors.alias)) {
                errors.alias = intl.formatMessage({
                    id: 'objectClassesFields.errors.invalidAlias',
                    defaultMessage: 'Invalid alias',
                });
            }

            setErrors(errors);
        },
        [intl]
    );

    const onSubmit = useCallback(
        async (
            formData: ClassFieldForm,
            { setErrors, setFieldValue, resetForm }: FormikHelpers<ClassFieldForm>
        ) => {
            const url =
                mode === FormMode.Edit
                    ? generatePath(OBJECT_CLASS_FIELD_DETAILS, {
                        id: classId,
                        fieldId: id,
                    })
                    : generatePath(OBJECT_CLASS_DETAILS_FIELDS, {
                        id: classId,
                    });

            const initial = omit(omit(initialValues, 'users'), 'user_groups');

            try {
                await sendData({
                    url,
                    data: parseFormDataToApi(formData) as ClassFieldForm,
                    fields: ClassFieldFormFields,
                    setErrors: parseAndSetErrors(setErrors),
                    initialData: initial,
                    successMessage:
                        mode === FormMode.Create
                            ? {
                                title: intl.formatMessage({
                                    id: 'misc.success',
                                    defaultMessage: 'Success!',
                                }),
                                subtitle: intl.formatMessage({
                                    id: 'objectClasses.fields.fieldHasBeenCreated',
                                    defaultMessage: 'Field has been created.',
                                }),
                            }
                            : undefined,
                    callback: async data => {
                        if (!data) return;

                        const { order, ...apiField } = data;

                        if (mode === FormMode.Create) {
                            dispatch(addField({ ...apiField, order }));

                            resetForm({});
                        } else {
                            dispatch(updateField(apiField));

                            setInitialValues({
                                ...(Object.fromEntries(
                                    Object.entries(data).map(([key, value]) => [
                                        key,
                                        value ?? undefined,
                                    ])
                                ) as ClassFieldForm),
                                users: formData.users,
                            });
                        }

                        dispatch(setSidebarData(panelId, {}));
                    },
                });
            } catch (e) {
                if (isNotFound(e) && mode === FormMode.Edit) openNoExistsFieldModal();
                if (isBadRequest(e) && formData.type === ObjectClassFieldTypes.User) {
                    // both users and groups could be deleted prior to save
                    if (e.response?.data?.options?.users) {
                        const availableUsers = await getAvailableUsers(formData.users);
                        setFieldValue(ClassFieldFormFields.Users, availableUsers);
                    }
                    if (e.response?.data?.options?.user_groups) {
                        const availableGroups = await getAvailableGroups(
                            formData.user_groups
                        );
                        setFieldValue(ClassFieldFormFields.Groups, availableGroups);
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, id, initialValues, mode, sendData]
    );

    const onConfirmNoExistsField = useCallback(() => {
        if (id) {
            dispatch(setObjectClassesFieldsSelectedRow(undefined));
            history.replace(history.location.pathname, {}); // field's ID is cleaned up from state

            dispatch(removeField(id));
            closeNoExistsFieldModal();
        }
    }, [closeNoExistsFieldModal, dispatch, history, id]);

    return {
        onSubmit,
        initialValues,
        setInitialValues,
        data,
        isLimitExceeded,
        isOpenNoExistsFieldModal,
        onConfirmNoExistsField,
        deletedUsers,
        ...rest,
    };
};