import { useCallback, useState } from 'react';
import {
  useUserGroupValidationMessages,
  useUserValidationMessages,
} from './hooks';
import { FulfillmentValidationParameters } from './types';

/**
 * Validates if user type field is possible to fulfill based on it's constraints like min/max values etc. based on received
 * users and groups.
 */
export const useValidateFulfillment = () => {
  const [tooltip, setTooltip] = useState('');
  const [customErrors, setCustomErrors] = useState<string[]>([]);

  const {
    noUsersToSelect,
    noUsersToSelectError,
    notEnoughUsersToSelect,
    notEnoughUsersToSelectError,
  } = useUserValidationMessages();

  const {
    noGroupsToSelect,
    noGroupsToSelectError,
    notEnoughGroupsToSelect,
    notEnoughGroupsToSelectError,
  } = useUserGroupValidationMessages();

  const validateCollection = useCallback(
    (
      {
        availableItemsAmount,
        isRequired,
        minValues = 0,
      }: FulfillmentValidationParameters,
      noElementsToSelectMessage: string,
      noElementsToSelectError: string,
      notEnoughElementsToSelectMessage: string,
      notEnoughElementsToSelectError: string
    ) => {
      if (availableItemsAmount >= minValues) {
        return;
      }

      setTooltip(
        availableItemsAmount
          ? notEnoughElementsToSelectMessage
          : noElementsToSelectMessage
      );

      if (!isRequired) {
        return;
      }

      setCustomErrors(
        availableItemsAmount
          ? [notEnoughElementsToSelectError]
          : [noElementsToSelectError]
      );
    },
    []
  );

  const validateFulfillment = useCallback(
    (
      usersRequirements: FulfillmentValidationParameters,
      groupsRequirements: FulfillmentValidationParameters
    ) => {
      validateCollection(
        usersRequirements,
        noUsersToSelect,
        noUsersToSelectError,
        notEnoughUsersToSelect,
        notEnoughUsersToSelectError
      );
      validateCollection(
        groupsRequirements,
        noGroupsToSelect,
        noGroupsToSelectError,
        notEnoughGroupsToSelect,
        notEnoughGroupsToSelectError
      );
    },
    [
      noGroupsToSelect,
      noGroupsToSelectError,
      noUsersToSelect,
      noUsersToSelectError,
      notEnoughGroupsToSelect,
      notEnoughGroupsToSelectError,
      notEnoughUsersToSelect,
      notEnoughUsersToSelectError,
      validateCollection,
    ]
  );

  return { validateFulfillment, tooltip, customErrors };
};
