import React, { useMemo } from 'react';
import { UsersAndGroupsExpandablePickerContext } from './UsersAndGroupsExpandablePickerContext';
import { UsersAndGroupsExpandablePickerContextProps } from './UsersAndGroupsExpandablePickerContextProvider.types';
import { useToggleSelection } from './hooks';

export const UsersAndGroupsExpandablePickerContextProvider = ({
  children,
  selectedItems,
  setSelectedItems,
  searchFilter,
  limits,
  errors,
  onExpandedPanelStateChange,
}: UsersAndGroupsExpandablePickerContextProps) => {
  const { toggleItemSelectionState } = useToggleSelection(setSelectedItems);

  const contextValue = useMemo(
    () => ({
      searchText: searchFilter ?? '',
      limits: limits,
      selectedItems: selectedItems,
      errors: errors,
      toggleItemSelectionState: toggleItemSelectionState,
      onExpandedPanelStateChange: onExpandedPanelStateChange,
    }),
    [
      errors,
      limits,
      onExpandedPanelStateChange,
      searchFilter,
      selectedItems,
      toggleItemSelectionState,
    ]
  );

  return (
    <UsersAndGroupsExpandablePickerContext.Provider value={contextValue}>
      {children}
    </UsersAndGroupsExpandablePickerContext.Provider>
  );
};
