import { useEffect, useState } from 'react';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';
import { EMPTY_OPTIONS, MOCKED_USERS } from '../CustomUserWidget.consts';
import {
  fetchUserFieldUsersAndGroups,
  prepareUserOptions,
  prepareUserGroupOptions,
} from '../utils';

export const useObjectClassUserFieldOptions = (
  objectClassFieldId: number | null,
  objectClassId: string | undefined,
  onOptionsFetched?: (options: SelectUserAndGroupOption) => void
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [options, setOptions] = useState<SelectUserAndGroupOption>(
    EMPTY_OPTIONS
  );

  useEffect(() => {
    (async () => {
      if (!objectClassFieldId || !objectClassId) {
        setOptions({
          users: MOCKED_USERS,
          groups: [],
        });
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const { users, groups } = await fetchUserFieldUsersAndGroups(
          objectClassId,
          objectClassFieldId
        );

        const userOptions = prepareUserOptions(users);
        const groupOptions = prepareUserGroupOptions(groups);

        const newOptions = {
          groups: groupOptions,
          users: userOptions,
        };
        setOptions(newOptions);

        if (onOptionsFetched) {
          onOptionsFetched(newOptions);
        }
      } catch {
        setOptions(previous => {
          return {
            ...previous,
            users: [],
          };
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [objectClassId, objectClassFieldId, onOptionsFetched]);

  return { options, isLoading };
};
