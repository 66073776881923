import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseCustomUserWidgetStyles = {
  isFieldDisabled?: boolean;
  withUnderline?: boolean;
  hasErrors?: boolean;
};

export const useUsersAndGroupsSelectStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      checkmarkIcon: {
        color: theme['primary-color'],
      },
      mainDropdown: {
        padding: 0,
      },
      selectItem: {
        minHeight: 47,
        lineHeight: '19px',
        '& .ant-select-item-option-content': {
          display: 'flex',
          alignItems: 'center',
        },
        '& mark': {
          padding: 0,
          backgroundColor: 'inherit',
        },
        '&.ant-select-item-option-disabled ': {
          '& .ant-avatar': {
            background: theme['border-color-base'],
          },
          '& .ant-select-item-option-content > div:not(:first-child)': {
            color: theme['disabled-switch-gray'],
            '& mark': {
              color: theme['disabled-switch-gray'],
            },
          },
        },
      },
      dropdownStyles: (props: UseCustomUserWidgetStyles) => ({
        minWidth: '350px !important',
        boxShadow: 'none',
        border: props?.hasErrors
          ? `1px solid ${theme['error-color']}`
          : `1px solid ${theme['border-color-base']}`,
        paddingBottom: 0,
      }),
      avatars: {
        cursor: ({ isFieldDisabled }: UseCustomUserWidgetStyles) =>
          isFieldDisabled ? 'inherit' : 'pointer',
        width: 350,
        padding: 2,
        borderBottom: ({ withUnderline }: UseCustomUserWidgetStyles) =>
          withUnderline ? `1px solid ${theme['border-color-base']}` : 'none',
        '& span.ant-avatar': {
          cursor: ({ isFieldDisabled }: UseCustomUserWidgetStyles) =>
            isFieldDisabled ? 'inherit' : 'pointer',
        },
      },
      dropdownAvatarsCommonHeight: {
        height: '2rem',
      },
      optionToolTip: {
        width: '100%',
      },
      optionContent: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      },
      minMaxInfo: {
        marginTop: 0,
      },
      editUserWrapper: {
        position: 'relative',
        display: 'flex',
      },
      messageBox: {
        bottom: '0px',
        left: 1,
        width: 348,
        borderTop: `1px solid ${theme['border-color-base']}`,
        position: 'relative',
      },
      messageBoxOnTop: {
        borderBottom: `1px solid ${theme['border-color-base']}`,
        borderTop: 'none !important',
        zIndex: -1,
      },
      emptyContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 18,
      },
    };
  }
);
