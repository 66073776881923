import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { ArrowRightIcon, SyncIcon } from 'components/Icon';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import { useUserGroupsSelectorStyles } from 'components/UsersGroupsSelector/UsersGroupsSelector.styles';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { ExpandedUserList } from './components/ExpandedUserList';
import { useUsersGroupsListItemExpandableStyles } from './ExpandablePickerUserGroupItem.styles';
import { ExpandablePickerUserGroupItemProps } from './ExpandablePickerUserGroupItem.types';
import Popover from 'components/lib/Popover';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersGroupsSelector/components/UsersAndGroupsExpandablePicker/contexts/UsersAndGroupsExpandablePickerContext';

/**
 * A single item that represents a user group on the left panel of UserAndGroupsExpandablePicker with
 * the logic to expand the right side panel.
 */
export const ExpandablePickerUserGroupItem = ({
  item,
  limitReached,
}: ExpandablePickerUserGroupItemProps) => {
  const sharedStyles = useUserGroupsSelectorStyles();
  const styles = useUsersGroupsListItemExpandableStyles();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    searchText,
    onExpandedPanelStateChange,
    selectedItems,
  } = useUsersAndGroupsExpandablePickerContext();

  const isSelected = selectedItems.groups.get(item.id) !== undefined;

  const elementRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const onOutsideClick = useCallback(() => {
    setIsOpen(false);

    if (onExpandedPanelStateChange) {
      onExpandedPanelStateChange(false);
    }
  }, [onExpandedPanelStateChange]);

  useOutsideClick(
    popoverRef,
    onOutsideClick,
    undefined,
    undefined,
    elementRef.current
  );

  const { getScrollableParent } = useScrollableParent(elementRef);

  const onItemClick = () => {
    if (disabled) {
      return;
    }

    const newOpenState = !isOpen;
    setIsOpen(newOpenState);

    if (onExpandedPanelStateChange) {
      onExpandedPanelStateChange(newOpenState);
    }
  };

  const disabled = limitReached;
  return (
    <div
      ref={elementRef}
      className={clsx(sharedStyles.optionItem, {
        [sharedStyles.optionDisabled]: disabled,
        [styles.itemOpenedBackground]: isOpen,
      })}
      onClick={onItemClick}
    >
      <div className={sharedStyles.optionContent}>
        <OptionGroupAvatar
          disabledText={undefined}
          option={item}
          searchValue={searchText}
          disabled={disabled}
          showUserCount
        />
        {isSelected && <SyncIcon className={styles.syncIcon} size={16} />}
        <ArrowRightIcon
          className={clsx(styles.arrowIcon, { [styles.arrowFlipped]: isOpen })}
          size={14}
        />
      </div>
      <Popover
        key={`popover-${item.id}`}
        content={
          <div ref={popoverRef} onClick={e => e.stopPropagation()}>
            <ExpandedUserList userGroup={item} />
          </div>
        }
        getPopupContainer={getScrollableParent}
        overlayClassName={styles.popoverOverlay}
        trigger='click'
        visible={isOpen}
        placement={'rightTop'}
        destroyTooltipOnHide
      />
    </div>
  );
};
