import React from 'react';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import clsx from 'clsx';
import { CheckMarkIcon } from 'components/Icon';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import { UsersGroupsListItemProps } from '../../types';
import { useUsersGroupsListItemSelectAndDeselectStyles } from './UsersGroupsListItemSelectAndDeselect.styles';
import { useUserGroupsSelectorStyles } from 'components/UsersGroupsSelector/UsersGroupsSelector.styles';

// Avoid turning these into 'everything components', build more if needed, they are cheap to build and if kept simple, easy to maintain

export const UsersGroupsListItemSelectAndDeselect = ({
  id,
  item,
  handleClick,
  selected,
  isDisabled,
  searchText,
  limitReached,
  additionalText,
}: UsersGroupsListItemProps) => {
  const styles = useUsersGroupsListItemSelectAndDeselectStyles();
  const sharedStyles = useUserGroupsSelectorStyles();

  const finalDisableState = (limitReached && !selected) || isDisabled;

  const handleOnClick = () => {
    if (finalDisableState) {
      return;
    }

    handleClick(item);
  };

  return (
    <div
      id={id}
      className={clsx(sharedStyles.optionItem, styles.itemsPadding, {
        [sharedStyles.optionDisabled]: finalDisableState,
      })}
      onClick={handleOnClick}
    >
      <div className={sharedStyles.optionContent}>
        {item.type === UserOrGroupSelectOptionType.User ? (
          <OptionAvatar
            disabledText={undefined}
            option={item}
            searchValue={searchText ?? ''}
            disabled={finalDisableState}
            selected={selected}
            additionalText={additionalText}
            disableDefaultSelectionStyle
          />
        ) : (
          <OptionGroupAvatar
            disabledText={undefined}
            option={item}
            searchValue={searchText ?? ''}
            disabled={finalDisableState}
            selected={selected}
            disableDefaultSelectionStyle
          />
        )}
        {selected && (
          <CheckMarkIcon className={styles.checkmarkIcon} size={14} />
        )}
      </div>
    </div>
  );
};
