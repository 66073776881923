import EmptyData from 'components/EmptyData';
import NoMatchesFound from 'components/NoMatchesFound';
import { Loader } from 'components/lib/Loader';
import React from 'react';
import { useIntl } from 'react-intl';
import { UsersAndGroupsExpandablePickerProps } from './types';
import { ExpandablePickerItemList } from './components/ExpandablePickerItemList';
import useShouldRenderOnTop from 'hooks/useShouldRenderOnTop';
import { getContainerRef } from 'components/InPlaceEditControls/InPlaceEditMultiSelect/utils';
import { useUsersAndGroupsExpandablePickerVisibleItems } from './hooks';
import { UsersAndGroupsExpandablePickerContextProvider } from './contexts/UsersAndGroupsExpandablePickerContext';
import { useUsersAndGroupsExpandablePickerStyles } from './UsersAndGroupsExpandablePicker.styles';
import { useUserGroupsSelectorStyles } from 'components/UsersGroupsSelector/UsersGroupsSelector.styles';
import { ValidationListing } from './components/ValidationListing';

/**
 * Content for UsersGroupsSelector that displays groups and users together on a single list.
 * Users are directly pickable using select & deselect UX.
 * Groups are expandable with dropdown which allows syncing the whole group or picking individual users belonging to a group.
 */
export const UsersAndGroupsExpandablePicker = ({
  availableItems,
  selectedItems,
  setSelectedItems,
  isLoadingAvailableItems,
  searchFilter,
  limits,
  errors,
  onExpandedPanelStateChange,
  testId,
}: UsersAndGroupsExpandablePickerProps) => {
  const isError = errors !== undefined && errors.length > 0;
  const styles = useUsersAndGroupsExpandablePickerStyles({
    isError,
  });
  const sharedStyles = useUserGroupsSelectorStyles();
  const { elementRef } = useShouldRenderOnTop<HTMLDivElement>(getContainerRef);

  const intl = useIntl();

  const {
    visibleItems,
    allItems,
  } = useUsersAndGroupsExpandablePickerVisibleItems(
    availableItems,
    selectedItems,
    searchFilter
  );

  return (
    <UsersAndGroupsExpandablePickerContextProvider
      searchFilter={searchFilter}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      limits={limits}
      errors={errors}
      onExpandedPanelStateChange={onExpandedPanelStateChange}
    >
      <div
        ref={elementRef}
        className={styles.errorBorder}
        data-testid={testId}
        onClick={e => e.stopPropagation()}
        onFocus={e => e.stopPropagation()}
      >
        <div className={styles.optionList}>
          {visibleItems.length > 0 && (
            <ExpandablePickerItemList items={visibleItems} />
          )}
          {visibleItems.length === 0 &&
            allItems.length > 0 &&
            !isLoadingAvailableItems && <NoMatchesFound />}
          {visibleItems.length === 0 &&
            allItems.length === 0 &&
            !isLoadingAvailableItems && (
              <EmptyData
                title=''
                description={intl.formatMessage({
                  id: 'misc.noUsersAvailable',
                  defaultMessage: 'No users available',
                })}
              />
            )}
          {isLoadingAvailableItems && (
            <div className={sharedStyles.loader}>
              <Loader size='small' />
            </div>
          )}
        </div>
        <ValidationListing />
      </div>
    </UsersAndGroupsExpandablePickerContextProvider>
  );
};
