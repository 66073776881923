import EmptyData from 'components/EmptyData';
import NoMatchesFound from 'components/NoMatchesFound';
import { useUserGroupsSelectorStyles } from 'components/UsersGroupsSelector/UsersGroupsSelector.styles';
import { useInfiniteScroll } from 'components/UsersGroupsSelector/hooks';
import { ExtendedUsersGroupsSelectorPopoverProps } from 'components/UsersGroupsSelector/types';
import { Loader } from 'components/lib/Loader';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { USERS_LIST_AUTOCOMPLETE } from 'utils/endpoints';
import {
  SelectUserOption,
  SelectUserGroupOption,
} from 'utils/types/selectInput.types';

// The following all take UsersGroupsSelectorPopoverProps and will work directly with <UsersGroupsSelector />
// Keep these simple and maintainable, they are cheap to build as most of the functionality is passed in Api backed search
// build a separate one with the same interface for in memory options / search if one is needed -  keep this small and component clean
export const UserList = ({
  searchText,
  users,
  setUsers,
  optionContent,
  maxSelectedUsers,
  uniqueIdentifier,
  searchHasFocus,
}: ExtendedUsersGroupsSelectorPopoverProps) => {
  const {
    results,
    onScroll,
    loading,
    initialLoadComplete,
    hasUnfilteredResults,
  } = useInfiniteScroll<SelectUserOption>({
    searchText: searchText,
    endpoint: USERS_LIST_AUTOCOMPLETE,
    blockInitialLoad: !searchHasFocus,
    responseToDataConversionFunction: user => user,
    itemUniqueIdGenerationFunction: item =>
      `${uniqueIdentifier}-${item.type}-${item?.value}`,
  });
  const styles = useUserGroupsSelectorStyles();
  const intl = useIntl();

  const handleClick = useCallback(
    (user: SelectUserOption | SelectUserGroupOption) => {
      if (users.some(item => item.id === user.id)) {
        setUsers(users.filter(item => item.id !== user.id));
      } else {
        setUsers([...users, user] as SelectUserOption[]);
      }
    },
    [setUsers, users]
  );

  const limitReached = !!maxSelectedUsers && users.length >= maxSelectedUsers;

  return (
    <div className={styles.optionList} onScroll={onScroll}>
      {!!results.length &&
        results?.map(({ item, domId }) => {
          const selected = users.some(user => user.id === item?.id);
          return (
            <div id={domId} key={item.id} className={styles.options}>
              {optionContent({
                selected,
                item,
                handleClick,
                searchText,
                limitReached,
              })}
            </div>
          );
        })}
      {!results.length &&
        !loading &&
        initialLoadComplete &&
        hasUnfilteredResults && <NoMatchesFound />}
      {!results.length &&
        !loading &&
        initialLoadComplete &&
        !hasUnfilteredResults && (
          <EmptyData
            title=''
            description={intl.formatMessage({
              id: 'misc.noUsersAvailable',
              defaultMessage: 'No users available',
            })}
          />
        )}
      {!initialLoadComplete && (
        <div className={styles.loader}>
          <Loader size='small' />
        </div>
      )}
    </div>
  );
};
