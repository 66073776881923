export const LOGIN = '/api/users/login/';
export const REFRESH_TOKEN = '/api/users/refresh-token/';
export const RESET_PASSWORD = '/api/users/reset-password/:token/password/';
export const RESET_PASSWORD_TOKEN = '/api/users/reset-password/:token/';
export const LOGOUT = '/api/users/logout/';
export const PERMISSIONS = '/api/users/permissions/';
export const PREFERENCES = '/api/users/preferences/';
export const GLOBAL_PREFERENCES = '/api/preferences/';

export const UI_CONFIG_HELP_MAPPING = '/api/ui-config/help-mapping/';
export const UI_CONFIG_LANDING_PAGE = '/api/ui-config/landing-page/';

export const TASK_TEMPLATES_LIST = '/api/task-templates/';
export const TASK_TEMPLATE_VIEW = '/api/task-templates/:id/';
export const TASK_TEMPLATES_USAGE = '/api/task-templates/:id/usage/';

export const OBJECT_RECORD_LIST = '/api/object-records/';
export const OBJECT_RECORD_AUTOCOMPLETE =
  '/api/object-records/autocomplete/?object_class=:classId&ordering=text&text__icontains=';
export const OBJECT_RECORD_DETAILS = '/api/object-records/:id/';
export const OBJECT_RECORD_OWNERS = '/api/object-records/:id/owners/';
export const OBJECT_RECORD_HISTORY = '/api/object-records/:id/history/';
export const OBJECT_RECORD_HISTORY_FILES =
  '/api/object-records/:recordId/history/files/:eventId/';
export const OBJECT_RECORD_OWNER =
  '/api/object-records/:objectRecordId/owners/:ownershipId/';

export const OBJECT_CLASSES_LIST = '/api/object-classes/';
export const OBJECT_CLASS_DETAILS = '/api/object-classes/:id/';
export const OBJECT_CLASS_USAGE = '/api/object-classes/:id/usage/';
export const OBJECT_CLASS_OWNERS = '/api/object-classes/:id/owners/';
export const OBJECT_CLASS_OWNER =
  '/api/object-classes/:objectClassId/owners/:ownershipId/';
export const OBJECT_CLASS_DETAILS_FIELDS = '/api/object-classes/:id/fields/';
export const OBJECT_CLASS_FIELD_DETAILS =
  '/api/object-classes/:id/fields/:fieldId/';
export const OBJECT_CLASS_FIELD_USAGE =
  '/api/object-classes/:id/fields/:fieldId/usage/';
export const OBJECT_CLASSES_AUTOCOMPLETE =
  '/api/object-classes/autocomplete/?text__icontains=';
export const OBJECT_CLASS_FIELDS_AUTOCOMPLETE_NO_FILTER =
  '/api/object-classes/:id/fields/autocomplete/?text__icontains=';
export const OBJECT_CLASS_FIELDS_AUTOCOMPLETE =
  '/api/object-classes/:id/fields/autocomplete/?for_filtering=true&text__icontains=';
export const OBJECT_CLASSES_OWNED_AUTOCOMPLETE =
  '/api/object-classes/autocomplete/owned/';
export const OBJECT_CLASS_PERMISSION_SETS =
  '/api/object-classes/:id/permission-sets/';
export const OBJECT_CLASS_PERMISSION_SET =
  '/api/object-classes/:objectClassId/permission-sets/:permissionSetId/';
export const OBJECT_CLASS_PERMISSION_SET_ASSIGNEES =
  '/api/object-classes/:objectClassId/permission-sets/:permissionSetId/assignees/users/';
export const OBJECT_CLASS_PERMISSION_SET_GROUPS =
  '/api/object-classes/:objectClassId/permission-sets/:permissionSetId/assignees/user-groups/';

export const OBJECT_CLASS_RECORD_PERMISSION_SETS =
  '/api/object-classes/:id/record-permission-sets/';
export const OBJECT_CLASS_RECORD_PERMISSION_SET =
  '/api/object-classes/:objectClassId/record-permission-sets/:permissionSetId/';
export const OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES =
  '/api/object-records/:recordId/permission-sets/:permissionSetId/assignees/';

export const OBJECT_CLASS_FORMS = '/api/object-class-forms/';
export const OBJECT_CLASS_FORM_DETAILS = '/api/object-class-forms/:id/';

export const OBJECT_CLASS_MODELS = '/api/object-models/';
export const OBJECT_CLASS_MODEL = '/api/object-models/:id/';
export const DELETE_OBJECT_CLASS_MODEL_CHILDREN =
  '/api/object-models/:id/object-records/:parentId/children/:childId';
export const OBJECT_CLASS_MODEL_CHILDREN =
  '/api/object-models/:id/object-class/:classId/children/';
export const OBJECT_RECORD_CHILDREN =
  '/api/object-models/:id/object-records/:parentRecordId/children/';
export const OBJECT_RECORD_CHILDREN_AUTOCOMPLETE =
  '/api/object-models/:modelId/object-records/:parentRecordId/children/autocomplete/?object_class=:objectClassId&ordering=text&text__icontains=';
export const OBJECT_MODEL_DETAILS_OBJECT_RECORDS =
  '/api/object-models/:modelId/object-records/';
export const OBJECT_RECORD_CHILDREN_BATCH =
  '/api/object-models/:id/object-records/batch/';

export const SEQUENCES_LIST = '/api/sequences/';
export const SEQUENCE_DETAILS = '/api/sequences/:id/';
export const SEQUENCE_ASSIGNEES = `/api/sequences/:id/defaults/task-stages/`;
export const SEQUENCE_STAGE_ASSIGNEES = `/api/sequences/:id/defaults/task-stages/:stageId/assignees/`;
export const SEQUENCER = '/app/sequencer/:id/';

export const TASK_GROUP_LIST = '/api/task-group-templates/';
export const TASK_GROUP_TEMPLATE_DETAILS = '/api/task-group-templates/:id/';
export const TASK_GROUP_TEMPLATE_USAGE = '/api/task-group-templates/:id/usage/';

export const API_VERSION = '/api/version/';

export const USERS_LIST = '/api/users/';
export const USERS_LIST_AUTOCOMPLETE =
  '/api/users/autocomplete/?text__icontains=';
export const USER_GROUPS_LIST_AUTOCOMPLETE =
  '/api/user-groups/autocomplete/?ordering=text&text__icontains=';
export const USERS_LIST_AUTOCOMPLETE_IN = '/api/users/autocomplete?id__in=';
export const USERS_GROUPS_LIST_AUTOCOMPLETE_IN =
  '/api/user-groups/autocomplete/?id__in=';
export const USERS_STATS = '/api/users/stats/';
export const USER_GROUPS = '/api/user-groups/';
export const USER_GROUP = '/api/user-groups/:id/';
export const USER_GROUP_USERS = '/api/user-groups/:id/users/';
export const USER_GROUP_MEMBERS = '/api/user-groups/:id/members/';
export const USER_GROUP_OWNERS = '/api/user-groups/:id/owners/';
export const USER_GROUP_USAGE = '/api/user-groups/:id/usage/';
export const USER_GROUP_PERMISSION_SETS =
  '/api/user-groups/:id/permission-sets/';
export const USER_GROUP_PERMISSION_SET =
  '/api/user-groups/:groupId/permission-sets/:permissionSetId';
export const USER_GROUP_PERMISSION_SET_ASSIGNEES =
  '/api/user-groups/:groupId/permission-sets/:permissionSetId/assignees/users/';
export const USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS =
  '/api/user-groups/:groupId/permission-sets/:permissionSetId/assignees/user-groups/';
export const USER = '/api/users/:id/';
export const USER_ACTIVATION_EMAIL = '/api/users/:id/send-activation-email/';

export const USER_ME = '/api/users/me/';
export const USER_SET_PASSWORD = '/api/users/me/set-password/';

export const RENEW_TOKEN = '/api/users/activate/:token/renew/';
export const FORGOTTEN_PASSWORD = '/api/users/reset-password/';
export const ACTIVATE_ACCOUNT = '/api/users/activate/:token/';
export const ACTIVATE_ACCOUNT_USER = '/api/users/activate/:token/password/';
export const PASSWORD_COMPLEXITY = '/api/security/password-complexity/';

export const ROLES_LIST = '/api/roles/';
export const ROLE = '/api/roles/:id/';

export const FORM_LIST = '/api/forms/';

export const MESSAGE_TEMPLATES_LIST = '/api/message-templates/';

export const TASKS_LIST = '/api/tasks/';
export const TASKS_DETAILS = '/api/tasks/:id/';
export const TASK_RESPONSE = '/api/tasks/:id/response/';
export const TASK_TEMPLATES_AUTOCOMPLETE =
  '/api/task-templates/autocomplete/?creation=manual&text__icontains=';
export const COMPLETE_TASK = '/api/tasks/:id/complete/';
export const SAVE_TASK = '/api/tasks/:id/save/';
export const TASK_STAGES = '/api/tasks/:id/stages/';
export const TASK_STAGE_ASSIGNES = '/api/tasks/:id/stages/:stageId/assignees/';
export const TASK_FIELDS = '/api/tasks/:id/class-fields/';
export const TASK_OWNERSHIP = '/api/tasks/:task_id/stages/:stage_id/owner/me/';
export const ASSIGN_TASK_OWNERSHIP =
  '/api/tasks/:taskId/stages/:stageId/owner/';
export const TASK_1TC = '/api/tasks/1tc/:id/';
export const TASK_1TC_SAVE = '/api/tasks/1tc/:id/save/';
export const TASK_1TC_COMPLETE = '/api/tasks/1tc/:id/complete/';
export const TASK_1TC_FIELDS = `/api/tasks/1tc/:id/class-fields/`;
export const TASK_OWNERS_AUTOCOMPLETE =
  '/api/users/autocomplete/?account_type!=one_time_completion&text__icontains=';
export const EMAIL_COLLECTORS_LIST = '/api/email-collectors/';

export const COMPANY_DETAILS = '/api/administration/company-details/';
export const STANDARD_AUTHENTICATION = '/api/security/';
export const SMTP_SETTINGS = '/api/administration/smtp-settings/';
export const SMTP_SETTINGS_TEST = '/api/administration/smtp-settings/test/';

export const FILE_UPLOAD = '/api/files/upload/';
export const FILE_UPLOAD_1TC = '/api/tasks/1tc/:id/upload/';
export const FILE_PUBLIC_STORAGE = '/api/files/public-storage/';

export const SINGLE_SIGN_ON_LIST = '/api/single-sign-on/';
export const SINGLE_SIGN_ON = '/api/single-sign-on/:id/';
export const SINGLE_SIGN_ON_PROVIDERS_LIST = '/api/single-sign-on/login/';
export const SINGLE_SIGN_ON_CALLBACK_API = '/api/single-sign-on/callback/:id/';

export const AUTHENTICATION_OBJECTS_LIST = '/api/authentication-objects/';
export const AUTHENTICATION_OBJECT = '/api/authentication-objects/:id/';
export const AUTHENTICATION_OBJECT_CREATE_TEST =
  '/api/authentication-objects/test/';
export const AUTHENTICATION_OBJECT_EDIT_TEST =
  '/api/authentication-objects/:id/test/';

export const CLASS_FORM_DETAILS = '/api/object-class-forms/:id/';

// DOCUMENT TEMPLATE ENDPOINTS
export const DOCUMENT_TEMPLATE_LIST =
  '/api/object-classes/:id/document-templates/';

export const DOCUMENT_TEMPLATE_DETAILS =
  '/api/object-classes/:objectClassID/document-templates/:id/';
export const DOCUMENT_TEMPLATE_DOWNLOAD =
  '/api/object-classes/:objectClassID/document-templates/:documentTemplateID/download/';
export const DOCUMENT_TEMPLATE_USAGE =
  '/api/object-classes/:objectClassID/document-templates/:id/usage/';
export const DOCUMENT_TEMPLATE_VALIDATION =
  '/api/object-classes/:objectClassID/document-templates/verify/';

// DOCUMENT TEMPLATE PANEL
export const DOCUMENT_TEMPLATE_PANEL_LIST =
  '/api/object-records/:recordId/documents/';
export const DOCUMENT_TEMPLATE_PANEL_DETAILS =
  '/api/object-records/:recordId/documents/:id/';
export const DOCUMENT_TEMPLATE_PANEL_CANCEL =
  '/api/object-records/:recordId/documents/:id/cancel/';
export const DOCUMENT_TEMPLATE_PANEL_DOWNLOAD =
  '/api/object-records/:recordId/documents/:id/download/:fileId/';
export const DOCUMENT_TEMPLATE_PANEL = '/api/object-records/documents/';
export const DOCUMENT_TEMPLATE_PANEL_FIELDS =
  '/api/object-records/:recordId/document-fields/';
export const THEME_CONFIG = '/api/ui-config/theme/';

// CUSTOM COMPONENT ENDPOINTS
export const CUSTOM_COMPONENT_LIST = '/api/custom-components/';
export const CUSTOM_COMPONENT_REPOSITORY = '/api/custom-components/repository/';
export const CUSTOM_COMPONENT_REPOSITORY_DETAILS =
  '/api/custom-components/repository/:id/';
export const CUSTOM_COMPONENT = '/api/custom-components/:id/';
export const CUSTOM_COMPONENT_USAGE = '/api/custom-components/:id/usage/';

// PSPDFKIT RELATED ENDPOINTS
export const PSPDFKIT_LICENSE = 'https://pspdfkit.autologyx.com/:clientHost.json';