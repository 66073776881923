import { AvatarItem } from 'components/lib/Avatar/types';
import { toast } from 'components/lib/toast';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { PanelMode } from 'utils/Enums/PanelMode';
import ToastType from 'utils/Enums/ToastType';
import { apiCall } from 'utils/api';
import errorToast from 'utils/functions/errorToast';

export const useAddGroupAssignees = (
  panelKey: FlexLayoutWindows,
  selectedGroups: AvatarItem[],
  postEndpoint: string,
  resourceName: string,
  fetchGroupAssignees: () => Promise<void>
) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { [panelKey]: sidebarData } = useSelector(getSidebarData);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const goToManagePanelMode = () => {
    dispatch(
      setSidebarData(panelKey, {
        ...sidebarData,
        mode: PanelMode.Manage,
      })
    );
  };

  const showSuccessToast = (assignedGroupsCount: number) => {
    toast(
      {
        title: intl.formatMessage({
          id: 'misc.success',
          defaultMessage: 'Success!',
        }),
        subtitle: intl.formatMessage(
          {
            id: 'misc.successfulyAssigedGroupsTo',
            defaultMessage: `{count, plural, one {# user group has} other {# user groups have}} been assigned to {name}.`,
          },
          { count: assignedGroupsCount, name: resourceName }
        ),
      },
      ToastType.Success
    );
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const selectedGroupIds = selectedGroups.map(({ id }) => {
        return id;
      });

      const { data } = await apiCall.post<unknown[]>(
        postEndpoint,
        selectedGroupIds
      );

      await fetchGroupAssignees();

      goToManagePanelMode();
      showSuccessToast(data.length);
    } catch {
      errorToast();
    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, handleSubmit };
};
