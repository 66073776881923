import { FormValue } from 'alx-dynamic-form';
import { useCallback, useEffect, useRef } from 'react';

/**
 * Due to lack of control of asynchronous state change for form field value using setValue function. This hook schedules a validation
 * after value truly changes (including it's asynchronous nature of setState)
 * @param value Widget value that should trigger revalidation when the time comes.
 * @param setValue The setValue function, as received from WidgetProps.
 * @param revalidate Revalidation function that should run after the value is truly updated.
 **/
export const useRevalidate = (
  value: FormValue,
  setValue: (v: FormValue) => void,
  revalidate: () => void
) => {
  const shouldValidate = useRef(false);

  const setValueWithValidation = useCallback(
    (newValue: FormValue) => {
      setValue(newValue);
      shouldValidate.current = true;
    },
    [setValue]
  );

  useEffect(() => {
    if (!shouldValidate.current) {
      return;
    }

    revalidate();
    shouldValidate.current = false;
  }, [revalidate, value]);

  return { setValueWithValidation };
};
