import React, { useEffect, useMemo } from 'react';
import { toast } from 'components/lib/toast';
import useData from 'hooks/useData';
import orderBy from 'lodash/orderBy';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { TASKS_DETAILS, TASK_STAGE_ASSIGNES } from 'utils/endpoints';
import { mapBaseUserInfoToAvatarItem } from 'utils/functions/mapUserToAvatarItem';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import { StageNames, TaskDetails } from 'utils/types/api/tasks.types';
import { useTasksPanelContext } from '../../TasksPanelModeContext';
import { DataToRemove } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { setTaskAssignees } from 'store/actions/tasksAssigneesActions';
import { getTaskAssignees } from 'store/selectors/tasksAssigneesSelectors';

export const useStagesAssignees = (
  setAssigneeToRemove: React.Dispatch<
    React.SetStateAction<DataToRemove | undefined>
  >
) => {
  const { task } = useTasksPanelContext();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [taskDetails, { loading, error, fetchData }] = useData<TaskDetails>(
    generatePath(TASKS_DETAILS, { id: task?.id })
  );

  const allStagesAssignees = useSelector(
    getTaskAssignees(taskDetails?.id.toString() ?? '')
  );

  const mappedSectionName: MappedObject<string, StageNames> = useMemo(
    () => ({
      [StageNames.Stage1]: intl.formatMessage({
        id: 'tasksPanel.stage_1Completion',
        defaultMessage: 'Stage 1 - completion',
      }),
      [StageNames.Stage2]: intl.formatMessage({
        id: 'tasksPanel.stage_2Approval',
        defaultMessage: 'Stage 2 - approval',
      }),
    }),
    [intl]
  );

  const stagesAssignees = useMemo(
    () =>
      allStagesAssignees?.map(({ name, assignees: { users } = {} }) => ({
        users: users
          ? orderBy(
              users.map(user => mapBaseUserInfoToAvatarItem(user)),
              [
                ({ firstName: name }) => name?.toLowerCase(),
                ({ lastName: surname }) => surname?.toLowerCase(),
              ]
            )
          : undefined,
        name: mappedSectionName[name] || name.replaceAll('_', ' '),
        id: name,
      })),
    [allStagesAssignees, mappedSectionName]
  );

  const handleDeleteAssignee = async ({ id, stageId }: DataToRemove) => {
    try {
      await apiCall.delete(
        generatePath(TASK_STAGE_ASSIGNES, { stageId, id: task?.id }),
        { data: { users: [id] } }
      );

      setAssigneeToRemove(undefined);

      toast({
        title: intl.formatMessage({
          id: 'misc.success',
          defaultMessage: 'Success!',
        }),
        subtitle: intl.formatMessage(
          {
            id: 'tasksPanel.removeAssigneeSuccessMsg',
            defaultMessage: '1 assignee removed from {taskName} - {stage}.',
          },
          { taskName: taskDetails?.name, stage: stageId.replaceAll('_', ' ') }
        ),
      });

      await fetchData();
    } catch {
      showDefaultErrorToast();
    }
  };

  useEffect(() => {
    if (!loading && taskDetails)
      dispatch(
        setTaskAssignees({
          taskId: taskDetails.id.toString(),
          stages: taskDetails.stages,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    loading,
    error,
    stagesAssignees,
    taskDetails,
    fetchData,
    handleDeleteAssignee,
    mappedSectionName,
  };
};
