import React from 'react';
import { useValidationListingStyles } from './ValidationListing.styles';
import { ValidationErrorList } from './components/ValidationErrorList';
import { ValidationLimitList } from './components/ValidationLimitList/ValidationLimitList';
import { useUsersAndGroupsExpandablePickerContext } from '../../contexts/UsersAndGroupsExpandablePickerContext';

/**
 * Lists limits and error messages below the users and groups list.
 */
export const ValidationListing = () => {
  const styles = useValidationListingStyles();
  const { limits, errors } = useUsersAndGroupsExpandablePickerContext();

  const hasErrors = errors !== undefined && errors.length > 0;
  const hasLimits = limits !== undefined;

  if (!hasErrors && !hasLimits) {
    return <></>;
  }

  return (
    <div className={styles.validationContainer}>
      {hasErrors
        ? errors !== undefined && <ValidationErrorList errors={errors} />
        : limits !== undefined && <ValidationLimitList limits={limits} />}
    </div>
  );
};
