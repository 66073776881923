import React, { MouseEvent, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useTasksPanelStyles from '../styles';
import FormCategory from 'pages/TaskTemplates/components/FormCategory';
import { ASSIGNEES_SECTION_ID, SELECT_USERS_WRAPPER } from 'utils/elementsIds';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar/OptionAvatar';
import useTaskAssigments from './hooks/useTaskAssigments';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { StagesAssignmentSectionProps } from './types';
import { TaskFormFields } from './hooks/types';
import { Col } from 'components/lib/Grid';
import FormField from 'pages/TaskTemplates/components/FormField';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import FAutocompleteUsersSelect from 'components/AutocompleteUsersSelect/FAutocompleteUsersSelect';
import { ButtonLink } from 'components/lib/Button';
import PeopleListElement from '../../RecordAccessPanel/components/PeopleListElement';
import orderBy from 'lodash/orderBy';
import Popover from 'components/lib/Popover';
import { useToggle } from 'hooks/useToggle';
import { Alert } from 'components/lib/Alert';
import { ExclamationMarkSquare } from 'components/Icon';
import { useLimitMessageStyles } from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/LimitMessage/styles';
import useLimitAlert from './hooks/useLimitAlert';
import Tooltip from 'components/lib/Tooltip';
import { EDIT_COMPLETE_ASSIGMENTS } from 'utils/testIds';

const StagesAssignmentSection: React.FC<StagesAssignmentSectionProps> = ({
  options,
}) => {
  const classes = useTasksPanelStyles({});
  const intl = useIntl();
  const {
    getDisabledText,
    onSelectChange,
    shouldOptionBeDisabled,
    selectedUsers,
    onDelete,
    areSelectedUsersInLimit,
    autocompleteTooltipTitle,
  } = useTaskAssigments(options);
  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { notificationType, message } = useLimitAlert({
    limit: options[TaskFormFields.Stage1]?.limit_assignees_standard,
    currentAssigneesCount: selectedUsers.length,
  });
  const styles = useLimitMessageStyles(notificationType);

  const isCompleteStageRequired = options[TaskFormFields.Stage1]?.required;

  const avatarsItems = useMemo(
    () =>
      selectedUsers.map(
        ({
          first_name: firstName,
          last_name: lastName,
          id,
          account_type: accountType,
        }) => ({ firstName, lastName, id, accountType })
      ),
    [selectedUsers]
  );

  const popoverContent = useMemo(
    () => (
      <div className={classes.selectedUsersList}>
        {orderBy(selectedUsers, [
          ({ first_name: name }) => name.toLowerCase(),
          ({ last_name: surname }) => surname.toLowerCase(),
        ]).map(
          ({
            first_name: firstName,
            last_name: lastName,
            id,
            account_type: accountType,
          }) => (
            <div className={classes.userWrapper} key={id}>
              <PeopleListElement
                {...{ firstName, lastName, id, accountType }}
                onDelete={() => onDelete(id, toggleOff)}
              />
            </div>
          )
        )}
      </div>
    ),
    [
      classes.selectedUsersList,
      classes.userWrapper,
      onDelete,
      toggleOff,
      selectedUsers,
    ]
  );

  const handleOnEditClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleOn();
  };

  const getPopupContainer = () =>
    document.getElementById(ASSIGNEES_SECTION_ID) || document.body;

  return (
    <div id={ASSIGNEES_SECTION_ID}>
      <FormCategory
        collapsible
        collapsibleContent={
          <FormField>
            <div className={classes.stagesAssignmentWrapper}>
              <Col>
                <FormLabel bolded={false} required={isCompleteStageRequired}>
                  <FormattedMessage
                    id='tasksPanel.form.complete'
                    defaultMessage='Complete'
                  />
                </FormLabel>
              </Col>
              <div className={classes.assigneesWrapper}>
                <Col span={12}>
                  <Tooltip
                    title={autocompleteTooltipTitle}
                    overlayClassName={classes.assigneesLimitTooltip}
                    {...{ getPopupContainer }}
                  >
                    <div
                      id={SELECT_USERS_WRAPPER}
                      className={classes.autocompleteSelectWrapper}
                    >
                      <FAutocompleteUsersSelect
                        name={TaskFormFields.Stage1}
                        required={isCompleteStageRequired}
                        onChange={onSelectChange}
                        className={classes.autocompleteSelect}
                        checkOptionIsDisabled={shouldOptionBeDisabled}
                        renderOption={(option, searchValue) => (
                          <OptionAvatar
                            {...{ option, searchValue }}
                            disabledText={getDisabledText(option)}
                            disabled={shouldOptionBeDisabled(option)}
                          />
                        )}
                        autocompleteUrl={
                          options[TaskFormFields.Stage1]?.autocomplete || ''
                        }
                        disabled={!areSelectedUsersInLimit}
                        placeholder={intl.formatMessage({
                          id: 'misc.searchForUsersAndGroups',
                          defaultMessage: 'Search for users and groups',
                        })}
                      />
                    </div>
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Popover
                    content={popoverContent}
                    trigger='contextMenu'
                    visible={isOpen}
                    onVisibleChange={(value: boolean) =>
                      value ? toggleOn() : toggleOff()
                    }
                    {...{ getPopupContainer }}
                    overlayClassName={classes.popoverWrapper}
                    placement={'topLeft'}
                  >
                    <div className={classes.avatarWrapper} onClick={toggleOff}>
                      <CustomAvatarGroup
                        items={avatarsItems}
                        disablePopover={isOpen}
                      />
                      {!!selectedUsers.length && (
                        <div className={classes.editLink}>
                          <ButtonLink
                            onClick={handleOnEditClick}
                            data-testid={EDIT_COMPLETE_ASSIGMENTS}
                          >
                            <FormattedMessage
                              id='misc.edit'
                              defaultMessage='Edit'
                            />
                          </ButtonLink>
                        </div>
                      )}
                    </div>
                  </Popover>
                </Col>
              </div>
            </div>
            {!!notificationType && (
              <div className={classes.alertWrapper}>
                <Alert
                  banner
                  {...{ message }}
                  icon={<ExclamationMarkSquare size={18} />}
                  className={styles.alert}
                />
              </div>
            )}
          </FormField>
        }
        className={classes.sectionWrapper}
      >
        <FormattedMessage
          id='tasksPanel.form.assignmentOfStages'
          defaultMessage='Assignment of stages'
        />
      </FormCategory>
    </div>
  );
};

export default StagesAssignmentSection;
