import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { SearchIcon, CheckMarkIcon } from 'components/Icon';
import { SelectOption as Option, Select } from 'components/lib/Select';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import { SelectUserOption } from 'utils/types/selectInput.types';
import { EditUserProps } from './types';
import useInPlaceEditUserStyles from './styles';
import useShouldRenderOnTop from 'hooks/useShouldRenderOnTop';
import { getContainerRef } from '../InPlaceEditMultiSelect/utils';
import FieldValidationMessage from '../components/FieldValidationMessage';
import MinMaxInfo from 'components/MinMaxInfo';
import InPlaceInputAfter from '../components/InPlaceInputAfter';
import Tooltip from 'components/lib/Tooltip';
import {
  USER_FIELD_CHECKMARK_ICON,
  USER_FIELD_DROPDOWN,
  USER_FIELD_OPTION,
  USER_FIELD_VALIDATION_TESTID,
} from 'utils/testIds';
import { Loader } from 'components/lib/Loader';
import NoMatchesFound from 'components/NoMatchesFound';

const EditUser = ({
  tempValue,
  options,
  errorMessage,
  maxSelected,
  minSelected,
  loading,
  setTempValue,
  onClose,
  onBlur,
}: EditUserProps) => {
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const styles = useInPlaceEditUserStyles({ hasErrors: !!errorMessage });
  const { elementRef, customHeight } = useShouldRenderOnTop<HTMLDivElement>(
    getContainerRef
  );

  const checkIfSelected = useCallback(
    (user: SelectUserOption) => {
      return tempValue?.users.some(id => id === user.id);
    },
    [tempValue]
  );

  const handleChange = useCallback(
    ({ value }) => {
      setTempValue(prev => {
        if (prev) {
          return prev.users.includes(value)
            ? { users: prev.users.filter(id => id !== value) }
            : { users: [...prev.users, value] };
        }
        return prev;
      });
    },
    [setTempValue]
  );

  const filteredOptions = useMemo(
    () =>
      options.filter(user => {
        const { is_deleted, first_name, last_name, username } = user;
        return (
          [first_name, last_name, username].some(el => el.includes(search)) &&
          !is_deleted
        );
      }),
    [options, search]
  );

  const isMaxReached = useMemo(
    () =>
      !!maxSelected && !!tempValue && maxSelected <= tempValue?.users.length,
    [maxSelected, tempValue]
  );

  const maxReachedMessage = intl.formatMessage(
    {
      id: 'errors.maxReachedMessage',
      defaultMessage:
        'The maximum number of users ({maxSelected}) have been added',
    },
    { maxSelected }
  );

  const userNoLongerAvailableMessage = intl.formatMessage({
    id: 'errors.userNoLongerAvailable',
    defaultMessage: 'User no longer available for selection',
  });

  const cantBeSelected = intl.formatMessage({
    id: 'errors.cantBeSelected',
    defaultMessage: "Can't be selected",
  });

  const maxExceededMessage = intl.formatMessage({
    id: 'errors.maxExceededMessage',
    defaultMessage: 'Too many users selected',
  });

  const optionToolTipMessage = useMemo(() => {
    if (!!maxSelected && !!tempValue && maxSelected < tempValue?.users.length) {
      return maxExceededMessage;
    } else if (
      !!maxSelected &&
      !!tempValue &&
      maxSelected === tempValue?.users.length
    ) {
      return maxReachedMessage;
    }
    return '';
  }, [maxSelected, tempValue, maxReachedMessage, maxExceededMessage]);

  const dropdownRender = useCallback(
    (menu: React.ReactElement) =>
      !loading ? (
        <div data-testid={USER_FIELD_DROPDOWN}>
          {menu}
          <div
            data-testid={USER_FIELD_VALIDATION_TESTID}
            onMouseDown={e => e.preventDefault()}
          >
            <FieldValidationMessage
              className={styles.messageBox}
              error={errorMessage}
              displayAfterParent
              message={
                minSelected || maxSelected ? (
                  <MinMaxInfo
                    className={styles.minMaxInfo}
                    maximum={maxSelected ? maxSelected : undefined}
                    minimum={minSelected ? minSelected : undefined}
                  />
                ) : undefined
              }
            />
          </div>
        </div>
      ) : (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      ),
    [
      loading,
      styles.messageBox,
      styles.minMaxInfo,
      styles.loaderWrapper,
      errorMessage,
      minSelected,
      maxSelected,
    ]
  );

  return (
    <div
      ref={elementRef}
      className={styles.editUserWrapper}
      style={{ height: customHeight }}
    >
      <Select
        showSearch
        labelInValue
        defaultOpen
        open
        autoFocus
        value={[]}
        loading={loading}
        filterOption={false}
        searchValue={search}
        onSearch={setSearch}
        onChange={handleChange}
        onBlur={onBlur}
        className={styles.selectStyles}
        dropdownClassName={styles.dropdownStyles}
        icon={<SearchIcon size={12} />}
        placeholder={intl.formatMessage({
          id: 'placeholders.searchForUsers',
          defaultMessage: 'Search for users',
        })}
        notFoundContent={<NoMatchesFound />}
        dropdownRender={dropdownRender}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {filteredOptions.map(option => {
          const isSelected = checkIfSelected(option);
          const { unselectable } = option;
          const disabled = (isMaxReached && !isSelected) || unselectable;
          return (
            <Option
              key={option.id}
              value={option.id}
              className={styles.selectItem}
              disabled={disabled}
              data-testid={USER_FIELD_OPTION}
            >
              <Tooltip
                shouldBeRendered={!!disabled}
                title={
                  unselectable
                    ? userNoLongerAvailableMessage
                    : optionToolTipMessage
                }
                className={styles.optionToolTip}
                zIndex={5000}
              >
                <div className={styles.optionContent}>
                  <OptionAvatar
                    searchValue={search}
                    disabledText={unselectable ? cantBeSelected : undefined}
                    disabled={disabled}
                    {...{ option }}
                  />
                  {isSelected && (
                    <CheckMarkIcon
                      data-testid={USER_FIELD_CHECKMARK_ICON}
                      className={styles.checkmarkIcon}
                      size={14}
                    />
                  )}
                </div>
              </Tooltip>
            </Option>
          );
        })}
      </Select>

      <Tooltip
        title={intl.formatMessage({
          id: 'misc.discardChanges',
          defaultMessage: 'Discard changes',
        })}
        className={styles.inPlaceEditAfter}
      >
        <div onMouseDown={onClose}>
          <InPlaceInputAfter onCloseClick={() => null} isLoading={false} />
        </div>
      </Tooltip>
    </div>
  );
};

export default EditUser;
