import { useCallback, useState } from 'react';
import { UsersAndGroupsSelectProps } from '../UsersAndGroupsSelect.types';

export const useSelectUsersAndGroups = ({
  isFieldDisabled,
}: UsersAndGroupsSelectProps) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleSelectOpen = useCallback(() => {
    if (isFieldDisabled) {
      return;
    }

    setIsSelectOpen(true);
  }, [isFieldDisabled]);

  const handleSelectClose = useCallback(() => {
    setIsSelectOpen(false);
  }, []);

  const onSelectOpenChange = useCallback(
    (isFocused: boolean) => {
      if (isFocused) {
        handleSelectOpen();
        return;
      }

      handleSelectClose();
    },
    [handleSelectOpen, handleSelectClose]
  );

  return {
    isSelectOpen,
    onSelectOpenChange,
  };
};
