import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useTabListStyles = createUseStyles((theme: ThemeState) => {
  return {
    tabs: {
      '& .ant-tabs-nav': {
        margin: '0px !important',
        background: theme['white-color'],
      },
      '& .ant-tabs-tab': {
        paddingLeft: 20,
        paddingRight: 20,
        margin: 0,
        borderBottom: `1px solid ${theme['border-color-base']} !important`,
        '& .ant-tabs-tab-btn': {
          color: theme['disabled-color'],
        },
        '&.ant-tabs-tab-active .ant-tabs-tab-btn': {
          fontWeight: 'bold',
          color: theme['primary-color'],
        },
      },
      '& .ant-tabs-nav::before': {
        borderBottom: 'none !important',
      },
      '& .ant-tabs-tab-disabled .ant-tabs-tab-btn': {
        color: `${theme['disabled-color']} !important`,
      },
      '& .ant-tabs-tab-active.ant-tabs-tab-disabled + .ant-tabs-ink-bar': {
        background: `${theme['disabled-color']} !important`,
      },
    },
  };
});
