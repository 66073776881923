import { useFormikContext } from "formik";
import { useCallback } from "react";
import { isNumber } from "lodash";
import { isInMinMaxRange } from "../utils/isInMinMaxRange";
import { ClassFieldFormFields } from "pages/ObjectClasses/enums";
import { ClassFieldForm } from "../../../../../types";

export const useHandleMaxMinValidation = () => {
    const {
        setFieldValue,
        values: {
            default_value: defaultVal,
            min_value: minValue,
            max_value: maxValue,
            type,
        },
    } = useFormikContext<ClassFieldForm>();

    return useCallback(
        (name: string, value?: number) => {
            let defaultValue: (undefined | number) = undefined;

            if (defaultVal) {
                defaultValue = isNumber(defaultVal) ? defaultVal : parseFloat(defaultVal);
            }

            const isMinValueChange = name === ClassFieldFormFields.MinValue;

            if (
                value !== undefined &&
                isInMinMaxRange(value, isMinValueChange, minValue, maxValue)
            ) {
                const valueKeyToChange = isMinValueChange
                    ? ClassFieldFormFields.MaxValue
                    : ClassFieldFormFields.MinValue;
                const valueToSet = type === 'int' ? Math.floor(value) : value;

                setFieldValue(valueKeyToChange, valueToSet);
            }

            if (isMinValueChange) {
                const isMinNotValid =
                    value && defaultValue && defaultValue < value;

                if (isMinNotValid)
                    return setFieldValue(ClassFieldFormFields.DefaultValue, undefined);
            } else if (name === ClassFieldFormFields.MaxValue) {
                const isMaxNotValid =
                    value && defaultValue && defaultValue > value;

                if (isMaxNotValid)
                    return setFieldValue(ClassFieldFormFields.DefaultValue, undefined);
            }
        },
        [defaultVal, maxValue, minValue, type, setFieldValue]
    );
};