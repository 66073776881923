import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useCustomUserWidgetStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      selectStyles: {
        maxWidth: 350,
        width: 350,
        position: 'relative',
        '& .ant-select-selector': {
          height: '32px !important',
          borderColor: theme['border-color-base'],
        },
      },
    };
  }
);
