import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import { getObjectClassSelectedPermissionSet } from 'store/selectors/objectClassPermissionsSelectors';
import { AppDispatch } from 'store/store';
import { OBJECT_CLASS_PERMISSION_SET_ASSIGNEES } from 'utils/endpoints';
import { apiCall } from 'utils/api';
import { getObjectClassPermissionSetAssignees } from 'store/actions/objectClassPermissionsActions';
import errorToast from 'utils/functions/errorToast';
import { getUserDetails } from 'store/selectors/authSelectors';
import { AssigneeToDelete } from 'utils/types/assigneeToDelete';
import { useCancelToken } from 'hooks/useCancelToken';
import axios from 'axios';

export const useUnassignClassPermissionUser = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id: objectClassId } = useParams<{ id: string }>();
  const { createCancelToken, cancelOngoingRequests } = useCancelToken();

  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );
  const userId = useSelector(getUserDetails)?.id;

  const [userToUnassign, setUserToUnassign] = useState<AssigneeToDelete>();
  const [isLoading, setIsLoading] = useState(false);

  const permissionSetId = selectedPermissionSet?.id?.toString() ?? '';
  const isUnassigningHimself = userId == userToUnassign?.id;

  const cancelUnassigning = () => {
    cancelOngoingRequests();
    setUserToUnassign(undefined);
  };

  const unassignUserFromClassPermission = async () => {
    if (!userToUnassign) {
      return;
    }

    try {
      setIsLoading(true);
      cancelOngoingRequests();

      const cancelToken = createCancelToken();

      const endpoint = generatePath(OBJECT_CLASS_PERMISSION_SET_ASSIGNEES, {
        objectClassId,
        permissionSetId,
      });

      await apiCall.delete(endpoint, {
        data: [userToUnassign.id],
        cancelToken,
      });

      dispatch(
        getObjectClassPermissionSetAssignees(objectClassId, permissionSetId)
      );
      setUserToUnassign(undefined);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      errorToast();
      setIsLoading(false);
    }
  };

  return {
    userToUnassign,
    isUnassigningHimself,
    isUnassigning: isLoading,
    cancelUnassigning,
    setUserToUnassign,
    unassignUserFromClassPermission,
  };
};
