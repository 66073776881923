import React from 'react';
import MinMaxInfo from 'components/MinMaxInfo';
import { MinMaxInfoLabelType } from 'components/MinMaxInfo/types';
import { ValidationLimitListProps } from './ValidationLimitList.types';
import { useValidationLimitListStyles } from './ValidationLimitList.styles';
import MessageBox from 'components/InPlaceEditControls/components/MessageBox';

/**
 * Lists validation requirements below user and groups listing (if applicable)
 */
export const ValidationLimitList = ({ limits }: ValidationLimitListProps) => {
  const styles = useValidationLimitListStyles();

  return (
    <MessageBox
      message={
        <div className={styles.validationListingContainer}>
          <MinMaxInfo
            key={`min-max-info-users`}
            className={styles.minMaxInfo}
            maximum={limits.maxUsers ?? undefined}
            minimum={limits.minUsers ?? undefined}
            labelType={MinMaxInfoLabelType.Users}
            checkboxField
          />
          <MinMaxInfo
            key={`min-max-info-groups`}
            className={styles.minMaxInfo}
            maximum={limits.maxGroups ?? undefined}
            minimum={limits.minGroups ?? undefined}
            labelType={MinMaxInfoLabelType.Groups}
            checkboxField
          />
        </div>
      }
      isError
    />
  );
};
