import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { StatusCodes } from 'http-status-codes';
import { AvatarItem } from 'components/lib/Avatar/types';
import { toast } from 'components/lib/toast';
import { SelectUserGroupOption } from 'utils/types/selectInput.types';
import { apiCall } from 'utils/api';
import ToastType from 'utils/Enums/ToastType';
import errorToast from 'utils/functions/errorToast';
import { useToggle } from 'hooks/useToggle';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import {
  AddUsersPanelProps,
  AddUsersPanelType,
  OwnersMode,
  SidebarData,
} from './types';
import { getUserGroupPermissionsGetSelectedPermissionSet } from 'store/selectors/userGroupPermissionsSelectors';
import { ErrorCodes } from 'utils/types/errorResponse';
import { useAutoSaveErrorModalContext } from 'contexts/AutoSaveErrorModalContext';
import { AxiosError } from 'axios';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import usePanels from 'hooks/usePanels';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

const useAddUsersPanel = <T>({
  panelType,
  panelKey,
  postApiEndpoint,
  avatars,
  usersCount,
  itemsLimit,
  fetchOwners,
  shouldFetchOnSuccess,
  onCancelClick,
  postDataKey,
  checkCustomCondition,
  customErrorMessage,
  customSuccessMessage,
  defaultMode,
  onSubmit,
  alreadyAddedDisabledMessage,
  getSelectedPermissionSet,
}: AddUsersPanelProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    [panelKey]: { selectedUsers = [], mode = OwnersMode.Adding } = {},
  } = (useSelector(getSidebarData) as unknown) as SidebarData;
  const hasSelectedUsers = selectedUsers.length > 0;
  const { showErrorModal } = useAutoSaveErrorModalContext();
  const selectedPermissionSet = useSelector(
    getSelectedPermissionSet ?? getUserGroupPermissionsGetSelectedPermissionSet
  );
  const {
    globalMethods: { closeComponent },
  } = useFlexLayoutContext();
  const { togglePanelsPreferences } = usePanels();
  const isFromCancelButton = useRef(false);

  const [
    isSubmitting,
    { toggleOn: startSubmitting, toggleOff: endSubmitting },
  ] = useToggle(false);
  const [
    showUnsavedChangesModal,
    {
      toggleOff: hideUnsavedChangesModal,
      toggleOn: displayUnsavedChangesModal,
    },
  ] = useToggle(false);

  const {
    setShouldBeDisplayed,
    setStoredModalFunctions,
  } = useConfirmationModalContext();

  const checkIsSelectedUser = useCallback(
    (idToCheck: number | string) =>
      selectedUsers.some(({ id }) => idToCheck === id),
    [selectedUsers]
  );

  const checkIsOwner = useCallback(
    (idToCheck: number | string) => avatars.some(({ id }) => idToCheck === id),
    [avatars]
  );

  const removeSeletedUser = useCallback(
    (userId: number | string) =>
      dispatch(
        setSidebarData(panelKey, {
          selectedUsers: selectedUsers.filter(({ id }) => id !== userId),
          mode,
        })
      ),
    [dispatch, mode, panelKey, selectedUsers]
  );

  const addSelectedUser = useCallback(
    (newUser: AvatarItem) => {
      if (!checkIsOwner(newUser.id) && !checkIsSelectedUser(newUser.id))
        dispatch(
          setSidebarData(panelKey, {
            selectedUsers: [newUser, ...selectedUsers],
            mode,
          })
        );
    },
    [checkIsOwner, checkIsSelectedUser, dispatch, mode, panelKey, selectedUsers]
  );

  const onSelectChange = async (props: SelectUserGroupOption | undefined) => {
    const { id, text } = props || {};

    if (!id) return;

    addSelectedUser({
      id,
      userGroup: text,
    });
  };

  const checkOptionIsDisabled = useCallback(
    (idToCheck: number) => {
      const disabled =
        checkIsOwner(idToCheck) || checkIsSelectedUser(idToCheck);

      return checkCustomCondition
        ? disabled || checkCustomCondition(idToCheck, selectedUsers)
        : disabled;
    },
    [checkIsOwner, checkIsSelectedUser, checkCustomCondition, selectedUsers]
  );

  const getDisabledText = useCallback(
    (idToCheck: number) => {
      if (checkIsOwner(idToCheck)) {
        if (alreadyAddedDisabledMessage) {
          return alreadyAddedDisabledMessage;
        }

        return intl.formatMessage({
          id: 'misc.alreadyAnOwner',
          defaultMessage: 'Already an owner',
        });
      } else if (checkIsSelectedUser(idToCheck)) {
        return intl.formatMessage({
          id: 'misc.alreadySelected',
          defaultMessage: 'Already selected',
        });
      }
    },
    [alreadyAddedDisabledMessage, checkIsOwner, checkIsSelectedUser, intl]
  );

  const messageOnAddOwners = useCallback(
    (count: number, users: AvatarItem[]) => {
      const getPanelTypeMessage = () => {
        if (panelType === AddUsersPanelType.PermissionSetAssignees) {
          return intl.formatMessage(
            {
              id: 'owners.assigneesAddedTo',
              defaultMessage: `{count, plural, one {# assignee} other {# assignees}} added to {name}.`,
            },
            { count, name: selectedPermissionSet?.name }
          );
        }
        if (panelKey === FlexLayoutWindows.SequenceDefaultAssignees) {
          return intl.formatMessage(
            {
              id: 'owners.defaultAssigneesAdded',
              defaultMessage: `{count, plural, one {# default assignee has} other {# default assignees have}} been added.`,
            },
            { count }
          );
        }
        return intl.formatMessage(
          {
            id: 'owners.ownersAdded',
            defaultMessage: `{count, plural, one {# owner has} other {# owners have}} been added.`,
          },
          { count }
        );
      };
      toast(
        {
          title: intl.formatMessage({
            id: 'misc.success',
            defaultMessage: 'Success!',
          }),
          subtitle: customSuccessMessage
            ? customSuccessMessage(count, users)
            : getPanelTypeMessage(),
        },
        ToastType.Success
      );
    },
    [customSuccessMessage, intl, panelKey, panelType, selectedPermissionSet]
  );

  const backToParentPanel = useCallback(
    async (withCancel?: boolean) => {
      if (onCancelClick && withCancel) onCancelClick();

      await dispatch(
        setSidebarData(panelKey, {
          mode: defaultMode,
        })
      );
    },
    [dispatch, defaultMode, panelKey, onCancelClick]
  );

  const handleSubmit = async () => {
    startSubmitting();

    try {
      if (onSubmit && !postApiEndpoint) {
        onSubmit(selectedUsers);
        endSubmitting();

        await backToParentPanel(true);

        return;
      }

      const users = selectedUsers.map(({ id }) => id);
      const { status, data } = await apiCall.post<T[]>(
        postApiEndpoint,
        postDataKey ? { [postDataKey]: users } : users
      );

      if (status === StatusCodes.CREATED) {
        if (shouldFetchOnSuccess) {
          if (fetchOwners) await fetchOwners();
        }
        await dispatch(
          setSidebarData(panelKey, {
            selectedUsers: [],
            mode,
          })
        );

        messageOnAddOwners(
          postDataKey ? users.length : data.length,
          selectedUsers
        );
        backToParentPanel(true);
      } else {
        endSubmitting();
        errorToast();
      }
    } catch (error) {
      const { response: { data = {}, status = undefined } = {} } = error || {};

      if (status === StatusCodes.FORBIDDEN && showErrorModal) {
        showErrorModal(error as AxiosError);

        return;
      }

      if (data?.error_code === ErrorCodes.ERR_LIMIT_EXCEEDED) {
        if (fetchOwners) await fetchOwners();
        await backToParentPanel(true);
        toast(
          {
            title: intl.formatMessage({
              id: 'assignees.assigneeNotAdded',
              defaultMessage: 'Failed to assign user',
            }),
            subtitle: intl.formatMessage({
              id: 'assignees.disabledButtonMessage',
              defaultMessage: 'Maximum limit reached.',
            }),
          },
          ToastType.Error
        );
        return;
      }

      // invoke custom toast or if undefined show default error toast
      const { title, subtitle } =
        customErrorMessage?.({
          errorCode: data.error_code,
          count: selectedUsers.length,
          itemsLimit,
        }) ?? {};

      if (title) {
        toast({ title, subtitle }, ToastType.Error);
      } else {
        errorToast();
      }

      endSubmitting();
    }
  };

  useEffect(() => {
    // back to ManageOwners panel after maximum number of owners has been reached during adding owners
    if (!isSubmitting && itemsLimit !== undefined && usersCount >= itemsLimit)
      backToParentPanel();
  }, [isSubmitting, itemsLimit, usersCount, backToParentPanel, dispatch, mode]);

  const onCancelAction = () => {
    if (onCancelClick) onCancelClick();

    backToParentPanel();
  };

  const handleCancel = () => {
    if (hasSelectedUsers) {
      isFromCancelButton.current = true;
      displayUnsavedChangesModal();

      return;
    }

    onCancelAction();
  };
  const usersSum = usersCount + selectedUsers.length;
  const isLimitExceeded = itemsLimit !== undefined && usersSum > itemsLimit;
  const isLimitAchieved = itemsLimit !== undefined && usersSum >= itemsLimit;

  const onConfirmCancelModal = () => {
    hideUnsavedChangesModal();
    if (isFromCancelButton.current) onCancelAction();
    else {
      dispatch(
        setSidebarData(panelKey, {
          selectedUsers: [],
          mode,
        })
      );
      closeComponent(panelKey);
      togglePanelsPreferences(panelKey, true);
    }
  };

  const onHideCancelModal = () => {
    isFromCancelButton.current = false;
    hideUnsavedChangesModal();
  };

  useEffect(() => {
    // set "true" because we control the panels actions by methods in setStoredModalFunctions
    setShouldBeDisplayed(panelKey, true);

    return () => setShouldBeDisplayed(panelKey, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStoredModalFunctions(
      panelKey,
      hasSelectedUsers
        ? {
            callback: displayUnsavedChangesModal,
          }
        : undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedUsers]);

  return {
    checkOptionIsDisabled,
    getDisabledText,
    handleCancel,
    handleSubmit,
    isSubmitting,
    isLimitExceeded,
    isLimitAchieved,
    onSelectChange,
    removeSeletedUser,
    selectedUsers,
    showUnsavedChangesModal,
    onConfirmCancelModal,
    hideUnsavedChangesModal: onHideCancelModal,
    hasSelectedUsers,
    backToParentPanel,
  };
};

export default useAddUsersPanel;
