import { ObjectRecordUserMetadata } from 'utils/types/api/objectRecords.types';
import {
  UserOrGroupSelectOptionType,
  SelectUserOption,
} from 'utils/types/selectInput.types';

export const prepareUserOptions = (apiUsers: ObjectRecordUserMetadata[]) => {
  const activeUsers = apiUsers.filter(({ is_deleted }) => !is_deleted);

  return activeUsers.map<SelectUserOption>(activeUser => ({
    type: UserOrGroupSelectOptionType.User,
    text: `${activeUser.first_name} ${activeUser.last_name}}`,
    value: activeUser.id,
    company: activeUser.company_name,
    ...activeUser,
  }));
};
