import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UserGroupsSelectorStyleProps = {
  errors?: string[];
};

export const useUserGroupsSelectorStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      optionList: {
        width: '100%',
        maxHeight: '256px',
        overflowY: 'scroll',
        boxShadow:
          '0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)',
      },
      options: {
        cursor: 'pointer',
      },
      loader: {
        position: 'relative',
        height: 30,
        width: 30,
        paddingTop: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      optionItem: {
        paddingLeft: '8px',
        minHeight: 47,
        lineHeight: '19px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div:first-child': {
          marginRight: 6,
        },
        '& mark': {
          padding: 0,
          backgroundColor: 'inherit',
        },
      },
      optionContent: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        '& > div:first-child': {
          marginRight: 6,
        },
      },
      optionDisabled: {
        '& .ant-avatar': {
          background: theme['border-color-base'],
        },
        '& div:not(:first-child)': {
          color: `${theme['disabled-switch-gray']} !important`,
          '& mark': {
            color: theme['disabled-switch-gray'],
          },
        },
        cursor: 'not-allowed',
      },
    };
  }
);
