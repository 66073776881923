import FormBuilderConfirmationModals from 'components/FormBuilderModal/FormBuilderConfirmationModals';
import FormBuilderHeader from 'components/FormBuilderModal/FormBuilderHeader';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { FormPreview2ContentProps, FormPreview2RefProps } from './types';
import widgetSets, { WidgetSet } from './widgets';
import { useDynamicForm } from 'alx-dynamic-form/dist/index';
import useFormPreviewStyles from './styles';
import { FB_FORM_MODAL_WRAPPER } from 'utils/elementsIds';
import { getKeysOfFieldsWithFiles, getKeysOfFieldsWithUsers } from './utils';
import Section from './components/Section';
import { FORM_PREVIEW_TESTID } from 'utils/testIds';
import customFormats from './customFormats';
import useTranslateError from './translateError';
import { useToggle } from 'hooks/useToggle';
import { FormValue } from 'alx-dynamic-form';
import { CustomFileItem } from 'components/lib/FileUpload/types';
import partition from 'lodash/partition';
import { useFileUploadContext } from './FileUploadContext';
import { InputTypes } from 'components/formBuilder/formBuilder/FormBuilder/enums';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { useScrollableParent } from 'hooks/useScrollableParent';

const FormPreview2Content: ForwardRefRenderFunction<
  FormPreview2RefProps,
  FormPreview2ContentProps
> = (
  {
    schema,
    uischema,
    withFormHeader = true,
    inPlaceEditMode, // should replace widgets with inPlaceEditMode widgets
    onCancel,
    onSubmit,
    title,
    submitButtonIcon,
    submitButtonContent,
    errors,
    inPlaceEditSize = 'medium',
    inPlaceEditUrl,
    initialValues,
    isCreateMode = false,
    onInPlaceSaveSuccess,
    readOnly, // disabled interaction on inPlaceEdit widgets
    saving,
    widgetsPopupContainer,
    disabled,
    onFormStateChange,
    hideOwners,
    skipUploading,
    preventModalAppearing = false,
    setFormData,
    testId,
    additionalFieldProps = {},
    customFieldResetFunction,
    wrapperId,
  },
  ref
) => {
  const classes = useFormPreviewStyles({});
  const translateError = useTranslateError();
  const parsedSchema = useMemo(() => JSON.parse(schema), [schema]);

  const fixedInitialVales = useMemo(() => {
    const dateTimeFields = Object.values(parsedSchema.properties)
      .map(({ properties }: any) => {
        return Object.keys(properties).filter(
          key =>
            properties[key].type === 'string' &&
            properties[key].format === InputTypes.DateTime
        );
      })
      .flat();

    const newInitialValues = { ...initialValues } as MappedObject<FormValue>;

    dateTimeFields.forEach(key => {
      const value = newInitialValues[key];

      if (typeof value === 'string') {
        newInitialValues[key] = moment(value).toISOString() as FormValue;
      }
    });

    return newInitialValues;
  }, [parsedSchema, initialValues]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { getScrollableParent } = useScrollableParent(containerRef);

  const {
    form,
    formValid,
    resetForm,
    isFormDirty,
    setExtraErrors,
    clearHiddensectiondata: clearHiddenSectionData,
    data,
  } =
    useDynamicForm({
      customFormats,
      layout: {
        data_schema: parsedSchema,
        ui_schema: JSON.parse(uischema),
      },
      data: fixedInitialVales || {},
      errorTranslations: translateError,
      widgetMap:
        widgetSets[
          inPlaceEditMode ? WidgetSet.inPlaceEdit : WidgetSet.standard
        ],
      editMode: !isCreateMode,
      additionalFieldProps: {
        inPlaceEditSize,
        inPlaceEditUrl,
        onInPlaceSaveSuccess,
        readOnly,
        hideOwners,
        getPopupContainer: widgetsPopupContainer || getScrollableParent,
        skipUploading,
        ...additionalFieldProps,
      },
      fields: [],
      sectionWidget: Section,
      disabled,
    }) || {};

  const [
    isRevertModalVisible,
    { toggleOn: showRevertModal, toggleOff: hideRevertModal },
  ] = useToggle(false);

  const debouncedUpdateFormData = useCallback(
    debounce((formData: MappedObject<FormValue>) => {
      if (setFormData) {
        setFormData(formData);
      }
    }, 300),
    [setFormData]
  );

  useEffect(() => {
    if (setFormData && data) {
      debouncedUpdateFormData(data);
    }
  }, [data, debouncedUpdateFormData, setFormData]);

  // update form errors whenever external arrors are updated
  useEffect(() => {
    if (!!errors && !!setExtraErrors) setExtraErrors(errors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = async () => {
    if (!onSubmit) return;

    const submittedData = clearHiddenSectionData(customFieldResetFunction);

    if (!submittedData) {
      return;
    }

    const keysOfFieldsWithFiles = getKeysOfFieldsWithFiles(uischema);

    const [filesFields, restFields] = partition(
      Object.entries(submittedData),
      ([key]) => key in keysOfFieldsWithFiles
    );
    const fieldsWithFiles = Object.fromEntries(filesFields) as MappedObject<
      CustomFileItem[]
    >;
    const commonFields = Object.fromEntries(restFields) as MappedObject<
      FormValue
    >;

    const usersFieldKeys = Object.keys(getKeysOfFieldsWithUsers(uischema));
    usersFieldKeys.forEach(key => {
      if (commonFields && Array.isArray(commonFields[key]))
        commonFields[key] = {
          users: (commonFields[key] as []).map(({ id }: { id: number }) => id),
        };
    });

    return onSubmit(commonFields, fieldsWithFiles);
  };

  const { uploadingFiles } = useFileUploadContext();

  useEffect(() => {
    if (!onFormStateChange) return;

    onFormStateChange({
      hasErrors: !formValid,
      isFormTouched: !!isFormDirty,
      isUploadingInProgress: uploadingFiles.length !== 0,
    });
  }, [data, formValid, isFormDirty, onFormStateChange, uploadingFiles.length]);

  useImperativeHandle(ref, () => ({
    resetForm: (noConfirmation?: boolean) => handleReset(noConfirmation),
    submitForm: handleSubmit,
    setFormUntouched,
  }));

  const setFormUntouched = () => {
    if (!onFormStateChange) return;
    onFormStateChange({
      hasErrors: !formValid,
      isFormTouched: false,
      isUploadingInProgress: uploadingFiles.length !== 0,
    });
  };

  const handleReset = (withoutConfirmation = false) => {
    if (!isFormDirty && !inPlaceEditMode) return;

    if (withoutConfirmation) {
      resetForm();

      return;
    }

    if (!isRevertModalVisible) {
      showRevertModal();

      return;
    }

    hideRevertModal();
    resetForm();
  };

  return (
    <div id={wrapperId} ref={containerRef} className={classes.formWrapper}>
      {withFormHeader && !inPlaceEditMode && (
        <FormBuilderHeader
          onCancel={onCancel}
          hasErrors={!formValid}
          resetForm={handleReset}
          isFormTouched={isFormDirty}
          isCreateMode={!!isCreateMode}
          hasSubmit={!!onSubmit}
          submitVisibleData={handleSubmit}
          {...{
            title,
            submitButtonContent,
            submitButtonIcon,
            saving,
          }}
        />
      )}
      <div
        id={FB_FORM_MODAL_WRAPPER}
        data-testid={testId ?? FORM_PREVIEW_TESTID}
      >
        {form}
      </div>
      <FormBuilderConfirmationModals
        {...{
          toggleRevertModal: hideRevertModal,
          confirmRevertData: handleReset,
          revertModalVisible: isRevertModalVisible,
          confirmSaveModalActive:
            !preventModalAppearing &&
            !!isFormDirty &&
            !!withFormHeader &&
            !inPlaceEditMode,
          confirmSaveModal: handleSubmit,
        }}
      />
    </div>
  );
};

export default forwardRef<FormPreview2RefProps, FormPreview2ContentProps>(
  FormPreview2Content
);
